// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    color: theme.palette.primary["light"],
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 200,
    margin: theme.spacing(0),
  },
  listItemText: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'black',
  },
  listItemTextSecondary: {
      fontSize: '20px',
      color: 'black',
  }
});

const monthNames = ["Jan.", "Feb.", "March", "April", "May", "June",
    "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."
];

const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;

    let dayOfWeek = dayNames[date.getDay()];
    let dayOfMonth = date.getDate(); // Get the day of the month
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    let zone = date.toTimeString().match(new RegExp("[A-Z](?!.*[\(])","g")).join('');

    let strTime = dayOfWeek + ' ' + dayOfMonth + ' ' + month + ' ' + year + ' ' + hours + ':' + minutes + ' ' + ampm + ' ' + zone;
    return strTime;
  }

// Component Class
class SharedSelfSubList extends Component {

  constructor(props, context) {
    super(props);

  }

  // For Use of AuthContext
  static contextType = AuthContext;

  state = {

  };

  // Takes input data, returns what to display
  render() {

    // Referenced below for setting styles
    const { classes } = this.props;
    
    const refName = (this.props.value.copied_program === null) ? 'shared_program' : 'copied_program';
    const selection = this.props.value[refName];
    let additionalName = ''
    if (selection.fname !== '_') {
        additionalName = ': ' + selection.fname + ' ' + selection.lname
    }
    const messageTxt = {
        'shared_program': 'Shared by ' + this.props.value.shared_from, 
        'copied_program': 'Revision ' + this.props.value[refName].revision + ' submitted by ' + 
            this.props.value.shared_from + additionalName,
    };
    let timeDate = this.props.value.created_at;

    //let dateMsg = new Date(timeDate).toString();
    //const dateOff = dateMsg.indexOf('(');
    //dateMsg = dateMsg.substring(0, dateOff);

    let dateMsg = new Date(timeDate);
    dateMsg = formatAMPM(dateMsg);
    let gradeMsg = "Grade: " + String(this.props.value.grade);

    const shareState = this.props.value.state;

    return (
      <Card key = {this.props.keyName}>
          <Grid
              spacing = {1}
              justify="center"
              direction="row"
              container
              className={classes.grid}
          >
              <Grid item xs={9}>
                  <Typography className={classes.listItemText}>
                      {this.props.value[refName].name}
                  </Typography>
                  <Typography className={classes.listItemTextSecondary}>
                      {dateMsg}
                  </Typography>
                  <Typography className={classes.listItemTextSecondary}>
                      {messageTxt[refName]}
                  </Typography>
                  
                  {shareState === 'graded' && (  
                    <Typography className={classes.listItemTextSecondary}>
                        {gradeMsg}
                    </Typography>
                  )}
              </Grid>
              {shareState === 'new' && (    
                  <Grid item xs={3}>                                    
                      <IconButton
                          name="acceptBtn"                
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                          style={{color:"green"}}
                          onClick={() => this.props.handleAcceptShare(this.props.keyIndx)}
                      >
                          <CheckIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                          name="closeBtn"                
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                          style={{color:"red"}}
                          onClick={() => this.props.handleDenyShare(this.props.keyIndx, shareState)}
                      >
                          <CloseIcon fontSize="inherit" />
                      </IconButton>
                  </Grid>
              )}
              {(shareState === 'accepted' || shareState === 'graded') && (
                  <Grid item xs={3}>
                      <IconButton
                          name="openBtn"
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                          style={{color:"green"}}
                          onClick={() => this.props.handleOpenShare(this.props.keyIndx, shareState)}
                      >
                          <OpenInNewIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                          name="closeBtn"                
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                          style={{color:"red"}}
                          onClick={() => this.props.handleDenyShare(this.props.keyIndx, shareState)}
                      >
                          <CloseIcon fontSize="inherit" />
                      </IconButton>
                  </Grid>
              )}
          </Grid>
      </Card>
    );
  }
}

export default withStyles(styles)(SharedSelfSubList);
