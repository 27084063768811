import React, { forwardRef, useEffect, useImperativeHandle, useState, useCallback } from 'react';
import { IconButton, Grid, Typography, Input, Button } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { makeStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {

    const primaryMain = theme.palette.primary.main;
    const lighterPrimary1 = theme.palette.primary["light"];

    const purpleColor = theme.palette.secondary.purple;

    return {
        mainGrid: (props) => ({
            display: 'flex',
            justifyContent: 'center',
            width: props.width, 
            minWidth: '100px', // Ensure the component has a minimum width
            height: '125px',
            border: `2.5px solid ${purpleColor}`,
            borderRadius: '50px',
            backgroundColor: lighterPrimary1,
            color: "white",
        }),
        inputField: {
            padding: theme.spacing(1),
            border: '1px solid #ccc',
            borderRadius: '15px',
            background: 'white', // White background
            width: '50px', // Limit the width
            textAlign: 'center', // Center the text
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'row', // Stack items vertically
            justifyContent: 'center', // Center the items vertically
            alignItems: 'center', // Center the items horizontally
            '& > *': {
                margin: theme.spacing(0.5), // Add small space around each item
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row', // Stack items vertically
            justifyContent: 'center', // Center the items vertically
            alignItems: 'center', // Center the items horizontally
            '& > *': {
                marginRight: theme.spacing(1), // Add small right space to each item
            },
        },
        flatPrimaryBtn: {
            background: purpleColor,
            color: 'white',
            //fontSize: '1.5rem',
            padding: theme.spacing(1), // Add padding to the button
            borderRadius: theme.shape.borderRadius * 5, // Make the edges more rounded
            '&:hover': {
                backgroundColor: lighterPrimary1,
            },
        },
    }
});

const HorizontalInputClicker = forwardRef(({ 
  width = '250px', defaultValue = 0, min = 0, max = 10, 
  title = [], disabled = false, ...props
}, ref) => {

  const [value, setValue] = useState(defaultValue);
  const [updating, setUpdating] = useState(false);
  const [inValue, setInValue] = useState(defaultValue);
    
  const classes = useStyles( {width} );
  
  useImperativeHandle(ref, () => ({
    
    doneUpdate() {
        setUpdating(false);
    },
    updateValue(newVal) {
        setValue(newVal);
    }

  }));

  useEffect(() => {
    if (updating) {
      props.hSliderChange(value, title[0]);
      setUpdating(false);
    }
  }, [value, updating, props.hSliderChange, title]);

  const handleInOnBlur = (event) => {
    // Remove leading zeros
    let inputValue = event.target.value.replace(/^0+(?!$)/, '');
    // Parse the input value as an integer
    let newValue = parseInt(inputValue, 10);
    // If the parsed value is NaN (e.g., if the input is a letter), set it to 0
    if (Number.isNaN(newValue)) {
        newValue = 0;
    }
    // Clamp the value between min and max
    newValue = Math.min(Math.max(newValue, min), max);
    // Update the value
    setInValue(newValue);
  }

  const handleIn = (event) => {
    setInValue(event.target.value);
  }

  const handleSet = useCallback(() => {
    setValue(inValue);
    setUpdating(true);
    //props.hSliderChange(value, title[1]);

  }, [inValue, value, setValue, title, setUpdating]);

  const increment = useCallback(() => {
    setUpdating(true);
    setValue((prev) => {
      const newValue = Math.min(prev + 1, max);
      //props.hSliderChange(newValue, title[1]);
      return newValue;
    });
  }, [max, setValue, title, setUpdating]);

  const decrement = useCallback(() => {
    setUpdating(true);
    setValue((prev) => {
      const newValue = Math.max(prev - 1, min);
      //props.hSliderChange(newValue, title[1]);
      return newValue;
    });
  }, [min, setValue, title, setUpdating]);

  return (
    <Grid 
        container 
        direction="column" 
        alignItems="center"
        className={classes.mainGrid}
    >
        <Grid container direction="column" alignItems="center">
            <Grid item> 
                <div className={classes.textContainer}>
                    {title.map((line, index) => (
                        <Typography key={index} variant="body1">{line}</Typography>
                    ))}
                </div>
            </Grid>
            <Grid item>
                <div className={classes.textContainer}>
                    <IconButton 
                        onClick={decrement} 
                        disabled={disabled || updating}
                        style={{ padding: 0, marginTop: '4.5px' }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="body1">{value}</Typography>
                    <IconButton 
                        onClick={increment} 
                        disabled={disabled || updating}
                        style={{ padding: 0, marginTop: '4.5px' }}
                    >
                        <ArrowForward />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs>
                <div className={classes.inputContainer}>
                    <Input 
                        classes={{ input: classes.inputField }}
                        name='inAngle'
                        onChange = {handleIn}
                        onBlur = {(e) => handleInOnBlur(e)}
                        onClick={(event) => event.target.select()}
                        value={inValue}
                        disabled={disabled || updating}
                        disableUnderline // Hide the underline
                    />
                    <Button
                        className={classes.flatPrimaryBtn}
                        onClick={handleSet}
                        disabled={disabled || updating}
                    >
                        Set
                    </Button>
                </div>
            </Grid>
        </Grid>
    </Grid>
  );
});

export default HorizontalInputClicker;