// Child Component of: 
// --- Program

// Import React and Material-UI Modules
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

import { AuthContext } from "../context/auth";


import NameProgram from "./drawers/NameProgram";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    form: {
        marginTop: theme.spacing(4),
    },
    formControl: {
        width: "90%",
        margin: theme.spacing(4),
    },
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(4),
        fontSize: '20px'
    },
    inputHelper: {
        marginLeft: theme.spacing(4),
        fontSize: '20px'
    },
    inputHelperSelect: {
        marginLeft: theme.spacing(0),
        fontSize: '20px'
    },
    actionButton: {
        type: "submit",
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        fontSize: '15px',
    },
    selectItemText: {
        fontSize: '20px',
    },
});


// Don't like doing it like this but oh well, update when needed
var options = {
    "PythonOnly": ['Python'],
    "LocoDrone": ['GUI', 'Python'],
    "LocoDroneT": ['GUI', 'Python'],
    "LocoDroneTT": ['GUI', 'Python'],
    "LocoArm": ['Python'],
    "LocoArmS": ['Python'],
    "LocoArmM": ['Python'],
    "LocoArmMS": ['Python'],
    "LocoIoT": ['Python'],
    "LocoWear": ['Python'],
    "LocoXtreme": ['GUI','Python'],
    "LocoScout": ['GUI','Python'],
}


// Component Class - ProgramCreate
class ProgramCreate extends Component {

    static contextType = AuthContext;


    // Class state
    state = {
        nameProgramElement: React.createRef(),
        programLanguage: options[this.context.AuthInstance.userAccess[0]][0],
        hardwareSelection: this.context.AuthInstance.userAccess[0],
        nameBuffer: "",
    };

    // General "state" Update handler
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'hardwareSelection') {
            this.setState( {programLanguage: options[event.target.value][0]} );
        }
    };

    // Create Program button pressed
    createProgram = event => {
        if (/^[a-zA-Z0-9_]+$/.test(this.state.nameBuffer)) {
            // Send Information to Parent component: Program
            let selection = {language: this.state.programLanguage, name: this.state.nameBuffer, instructions: "", revision: 1, deleted: false};
            var progDetails = {type: "new", selection: selection, hardware: this.state.hardwareSelection};
            this.props.onCreateProgram(progDetails);
        } else {
            this.state.nameProgramElement.current.handleOpen();
        }
    }

    closeBadName = () => {
        this.state.nameProgramElement.current.handleClose();
    }

    // Handler to update text stored in "state" when Input field text for program name is changed
    handleNameUpdate = event => {
        this.setState({nameBuffer: event.target.value});
    };

    // Takes input data, returns what to display
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;
        
        // What to Display - Program name Input field, drop-down Select program type, create program Button
        return (
            <div>
                <FormControl fullWidth className={classes.form}>
                    <Input className={classes.inputField}
                      id="standard-adornment-pn"
                      aria-describedby="standard-pn-helper-text"
                      inputProps={{
                        'aria-label': 'pn',
                      }}
                      onChange = {this.handleNameUpdate}
                      autoComplete="off"
                    />
                    <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">Program Name</FormHelperText>
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                >
                    <Select
                        value={this.state.hardwareSelection}
                        onChange={this.handleChange}
                        aria-describedby="hardware-pn-helper-text"
                        className={classes.selectItemText}
                        input={
                        <OutlinedInput
                            labelWidth={this.state.labelWidth}
                            name="hardwareSelection"
                        />
                        }
                    >
                        {this.context.AuthInstance.userAccess.map((val) => {
                            return (
                                <MenuItem key={val} value={val} className={classes.selectItemText}>
                                    {val}
                                </MenuItem>
                            );
                        })}                        
                    </Select>
                    <FormHelperText className={classes.inputHelperSelect} id="hardware-pn-helper-text">Product Type</FormHelperText>
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                >
                <Select
                    value={this.state.programLanguage}
                    onChange={this.handleChange}
                    aria-describedby="progtype-pn-helper-text"
                    className={classes.selectItemText}
                    input={
                    <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="programLanguage"
                    />
                    }
                >
                    {options[this.state.hardwareSelection].map((val) => {
                        return (
                            <MenuItem key={val} value={val} className={classes.selectItemText}>
                                {val}
                            </MenuItem>
                        );
                    })}
                </Select>       
                <FormHelperText className={classes.inputHelperSelect} id="progtype-pn-helper-text">Program Type</FormHelperText>
                </FormControl>  
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                >       
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.actionButton}
                    onClick={this.createProgram}
                  >
                    Create Program
                </Button>
                </FormControl>
                <NameProgram
                    ref={this.state.nameProgramElement}
                    onClose={this.closeBadName}
                />
            </div>

        );
    }
}

export default withStyles(styles)(ProgramCreate);
