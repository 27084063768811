// Child Component of: 
// --- ProgramCoding

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import BaseBlock from "../blocks/BaseBlock";

import ldtEmergency from '../../images/LocoDroneT/drone_emergency.svg';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 800,
    top: '10%',
    transform: 'translateY(0)',
    minHeight: '425px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    square: false,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperConnect: {
    width: 600,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    square: false,
    boxShadow: theme.shadows[5],
    marginTop: theme.spacing(2),
  },
  actionButton: {
    justify: "right",
    margin: theme.spacing(2),
    color: "red",
  },  
  titleText: {
    color: "white",
    margin: theme.spacing(1),
  },  
  blockNumText: {
    margin: theme.spacing(1),
  }
  
});



// Component Class - ProgramDisplay
class ProgramGUIDisplay extends React.Component {
  
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        // Bind Certain Functions to "this"
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.setClose = this.setClose.bind(this);


    }
    // Class state
    state = {
        isOpen: false,
        stdOut: "",
        stopClose: "Stop and Close",
        bodyColor: "red",
        blockIndx: -1, // 0
        blockMsg: "Block Number: ",
        blockListI: null,
        currentBlock: React.createRef(),
        canDisplay: false,
        ldtExtraEnabled: false,
    }

    componentDidMount() {
        window.plotDivRef = React.createRef();
    }


    // For Displaying Self (Modal), Called by Parent ProgramCoding
    handleOpen = (startMsg, startMsgBgColor) => {
        this.setState( {isOpen: true, ldtExtraEnabled: false} );
        this.setState( {stopClose: "Stop and Close", startMsg: startMsg, startMsgBgColor: startMsgBgColor} );
    };

    handleUpdateMessage = (startMsg, startMsgBgColor) => {
        this.setState( {startMsg: startMsg, startMsgBgColor: startMsgBgColor} );
    }

    handleBlockListISet = (blockListI) => {
        this.setState( {blockListI: blockListI} );
    }

    // For Stopping Program Run
    handleClose = () => {
        if (this.state.stopClose === "Stop and Close") {
            this.setState( {blockListI: null} );
            this.setState( {canDisplay: false} );
            this.setState( {isOpen: false} );
            // Tell Parent ProgramCoding to Call its stopRunning() function
            this.props.onStop();
            //this.setState( {stopClose: "Close"} );
        } else {
            // Update Self (Modal) Open state
            this.setState( {blockListI: null} );
            this.setState( {canDisplay: false} );
            this.setState( {isOpen: false} );
        }
    };

    handleEmergency = () => {
        // 
        this.setState( {
            blockListI: null,
            canDisplay: false,
            ldtExtraEnabled: false,
            isOpen: false,
        } );
        
        this.props.onEmergency();
    }
    
    handleLDTExtra = () => {
        this.setState( {ldtExtraEnabled: true} )
    }

    // Update stopClose state for on-Modal Button text
    setClose = (text) => {
        //this.setState( {stopClose: text} );
        this.setState( {blockListI: null} );
        this.setState( {canDisplay: false} );
        this.setState( {isOpen: false} );
    };

    updateCanDisplay = (inState) => {
        this.setState( {canDisplay: inState} );
    }

    updateDisplay = (blockIndx, blockListI = null) => {
        this.setState( {blockIndx: blockIndx, blockListI: blockListI} );
        if (this.state.currentBlock.current !== null) {
            this.state.currentBlock.current.refreshType(blockListI);
        }
    };


    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        const marginLOffset = -1.0 * (this.props.cardWidth / 2);

        const blockMsg = this.state.blockMsg + this.state.blockIndx;

        // What to Display - The Modal: Header, Button, "pre" tagged Text
        return (
            <div>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                        onClick: (e) => e.stopPropagation() // Prevent closing on outside click
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper} 
                    style = {{borderRadius: "25px",}}>
                        <Grid 
                            container 
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={this.state.ldtExtraEnabled ? 6: 8}>
                                <h2 id="transition-modal-title">Program</h2>
                            </Grid>
                            <Grid item xs="auto">
                                {this.state.ldtExtraEnabled && (
                                    <Button
                                        variant ="contained" 
                                        className={classes.actionButton} 
                                        onClick={this.handleEmergency}
                                        style={{fontSize: '20px'}}
                                    >
                                        <img 
                                            src = {ldtEmergency} 
                                            width={"20px"}
                                            style = {{background: 'white'}}
                                        />
                                    </Button>
                                )}
                                <Button 
                                    className={classes.actionButton} 
                                    onClick={this.handleClose}
                                    style={{fontSize: '20px'}}
                                >
                                    {this.state.stopClose}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.canDisplay === true && (
                                <Typography 
                                    className={classes.blockNumText}
                                    variant='h5'
                                >
                                    {blockMsg}
                                </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {((this.state.canDisplay === true) && (this.state.blockListI === null)) && (
                                <Card 
                                    className={classes.paperConnect}
                                    style={{backgroundColor: this.state.startMsgBgColor, 
                                            marginLeft: `calc(50% + ${marginLOffset}px)`,
                                            borderRadius: 20,
                                            width:this.props.cardWidth,
                                        }}
                                >
                                    <pre 
                                        className={classes.titleText}
                                        style={{fontSize: 'x-large', fontFamily: 'Roboto'}}
                                    >
                                        {this.state.startMsg}
                                    </pre>
                                </Card>
                                )}
                                {((this.state.canDisplay === true) && (this.state.blockListI !== null)) && (
                                    <BaseBlock 
                                        ref={this.state.currentBlock}
                                        hardwareSelection={this.props.currentHardware}
                                        index={0}
                                        i = {this.state.blockIndx}
                                        blockType={this.state.blockListI.name}
                                        control={this.state.blockListI.control}
                                        cardWidth={this.props.cardWidth}
                                        indentationLevel={0}
                                        isDisabled={true}
                                        handleUpdateState={this.props.handleUpdateState}
                                    />
                                )}
                            </Grid>
                        </Grid>                        
                    </div>
                </Fade>
                </Modal>
            </div>
        );
    };
}

export default withStyles(styles)(ProgramGUIDisplay);
