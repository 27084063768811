import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
//import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
//import Divider from '@material-ui/core/Divider';

import { AuthContext } from "../context/auth";

import Topbar from "./Topbar";

import bittleDog from "../images/LocoScout/products-dog.webp";
import bittleServos from "../images/LocoScout/scout_servo_index.svg";//"../images/LocoScout/dog_servos.webp";
//import bittlePostures from "../images/LocoScout/dog_posture.webp";

import { 
    bleDeviceLS, STATUS_FAILURE, LS_SITE_LINK,
    CALIB_MIN, CALIB_MAX, LS_SKILLS, LS_CONTROLS,
    LS_SERVO_INDICES, LS_SERVO_NAMES, LS_SERVO_NAMES_L, LS_SERVO_NAMES_R 
} from "../libraries/LocoScoutStore";
//import VerticalSlider from "./common/VerticalSlider";
//import GradientButton from "./common/GradientButton";
//import HorizontalSlider from "./common/HorizontalSlider";
import HorizontalInputClicker from "./common/HorizontalInputClicker";

import GeneralMessage from "./drawers/GeneralMessage";

import { lighten } from '@material-ui/core/styles';

const AdvBackgroundShape = require("../images/LocoScout/products-bg.webp");

const UPDATE_DELAY = 1000; // Delay in milliseconds for updating the sliders
const DISCONNECT_MSG_DELAY = 3000; // Delay in milliseconds for disconnecting the device
const CALIB_MSG_DELAY = 3000; // Delay in milliseconds for calibration messages
const POSTURE_MSG_DELAY = 3000; // Delay in milliseconds for posture messages

const CALIB_BUTTONS = {
    'CALIBRATE': 'CALIBRATE', 
    'SAVE': 'SAVE',
    'ABORT': 'RESET CHANGES'
};
const POSTURE_BUTTONS = {
    'REST': LS_CONTROLS['Rest'], 
    'STAND UP': LS_SKILLS['posture']['Standing'], 
    'WALK': LS_SKILLS['gait']['Walk']['F']
};
const POSTURE_BUTTON_NAMES = {
    'REST': 'REST',
    'STAND UP': 'STAND UP',
    'WALK': 'WALK'
}

const INPUT_CLICK_W = '210px';

// Material-UI CSS-type Style Specifications
const styles = (theme) => {

    const primaryMain = theme.palette.primary.main;
    const lighterPrimary1 = theme.palette.primary["light"];
    //const lighterPrimary2 = lighten(primaryMain, 0.75);

    //const secondaryMain = theme.palette.secondary.main;
    //const lighterSecondary1 = lighten(secondaryMain, 0);
    //const lighterSecondary2 = lighten(secondaryMain, 1);

    const purpleColor = theme.palette.secondary.purple;

    const tertiaryMain = theme.palette.tertiary.main;
    const lighterTertiary1 = lighten(tertiaryMain, 0);
    const lighterTertiary2 = lighten(tertiaryMain, 1);

    return {
        root: {
            flexGrow: 1,
            //backgroundColor: theme.palette.grey["100"],
            overflow: "auto",
            //background: `url(${backgroundShape}) no-repeat`,
            //backgroundImage: `linear-gradient(to right, ${lighterTertiary1}, ${lighterTertiary2})`,
            background: "white",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minWidth: '300px', // Set a minimum width
            [theme.breakpoints.down('sm')]: { // Adjust the breakpoint as needed
              minWidth: '100%', // Ensure it takes full width on small screens
              overflow: 'scroll', // Enable scrolling on small screens
            },
            //paddingBottom: 200
        },
        buttonBar: {
            backgroundColor: lighterPrimary1,
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
        },
        formControl: {
            width: "95%",
            justify: "center",
            margin: theme.spacing(0),
        },
        inputField: {
            width: "90%",
            marginLeft: theme.spacing(2),
        },
        inputHelper: {
            marginLeft: theme.spacing(4),
        },
        grid: {       
            width: '95%', 
            flexGrow: 1,
            margin: theme.spacing(4),
        },
        boxGraphs: {
            backgroundColor: theme.palette.primary["light"],
            //maxWidth: '90%',
        },
        instructBox: {
            backgroundColor: theme.palette.primary["light"],
        },
        speedBox: {
            backgroundColor: theme.palette.primary["light"],
            margin: '10px',
        },
        paper: {
            //flexGrow: 1,
            padding: theme.spacing(1),
            textAlign: "center",
            alignItems:"center",
            justifyContent:"center",
            color: theme.palette.primary,
            backgroundColor: theme.palette.primary["light"],
            maxWidth: '80%',
            borderRadius:'25px',
        },
        graphSpec: {
            alignItems:"center",
            justifyContent:"center",
            borderRadius:'25px',
            padding: 10,
            width: 500,
        },
        actionButton: {
            justify: "center",
            margin: theme.spacing(1),
            color: theme.palette.primary["contrastText"],
            backgroundColor: lighterPrimary1,
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark, // Change this to your desired hover color
            },
        },
        input: {
            justify: "center",
            margin: theme.spacing(2),
            color: theme.palette.primary["contrastText"],
            backgroundColor: theme.palette.primary["light"],
        },
        about_root: {
            display: 'flex',
            maxHeight: `calc(100vh - ${window.navBarOffset})`, 
            minHeight: `calc(100vh - ${window.navBarOffset})`, 
            overflow: 'auto',
            //overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: `#040833 url(${AdvBackgroundShape}) no-repeat`,
            backgroundSize: "auto 100%",//"cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        },
        image: {
            //borderRadius: '10px',
            //border: '1px solid black',
            width: '40%',
            height: 'auto',
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        text: {
            width: '50%',
            textAlign: 'left',
            color: '#3f51b5',
            fontWeight: 'bold',
            marginLeft: theme.spacing(2),
        },
        inlineText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1),
        },  
        gradientButton: {
            textTransform: 'none',
            background: 'linear-gradient(135deg, #275ff6 35%, #bae9ff 100%)',
            color: 'white',
            size: 'large', // Make the button larger
            fontSize: '1.5rem',
            padding: theme.spacing(1), // Add padding to the button
            borderRadius: theme.shape.borderRadius * 2, // Make the edges more rounded
            '&:hover': {
                background: 'linear-gradient(-45deg, #275ff6 35%, #bae9ff 100%)',
            },
        },
        marginRight: {
            marginRight: theme.spacing(1),
        },
        marginBottom: {
            marginBottom: theme.spacing(3),
        },
        btnsTopRowL: {
            flex: 1, 
            display: 'flex', 
            justifyContent: 'space-around',//justifyContent: 'right',
            marginLeft: '50px',
            gap: '10px',
        },
        btnsTopRowR: {
            flex: 1, 
            display: 'flex', 
            justifyContent: 'space-around',//justifyContent: 'left',
            marginRight: '10px',
            gap: '10px',
        }
    };
};


  
class DogRC extends Component {
  
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.LSMsgElement = React.createRef();
        this.horzInputClickerRefsL = {};
        this.horzInputClickerRefsR = {};

        // Initialize refs for each servo name
        LS_SERVO_NAMES_L.forEach(name => {
          this.horzInputClickerRefsL[name] = React.createRef();
        });
        LS_SERVO_NAMES_R.forEach(name => {
          this.horzInputClickerRefsR[name] = React.createRef();
        });
        this.vertInputClickerRefs = { [LS_SERVO_NAMES[0]]: React.createRef() };
    }

    state = {
        isConnected: false,
        componentsDisabled: false,
        calibrationMode: false,
    };

    componentWillUnmount() {
        this.componentWrapup();
    }

    componentWrapup = async () => {
        if (this.state.isConnected) {
            await bleDeviceLS.abortCalibration();
            await bleDeviceLS.disconnect();
        }
    }
    
    handleConnect = async () => {
        if (this.state.isConnected) {
          // Disable components
          this.setState({ componentsDisabled: true });

          await bleDeviceLS.abortCalibration();
          await this.handleDisconnect();

          // Enable components
          this.setState({ 
            componentsDisabled: false, 
            calibrationMode: false,
        });
        } else {
          try {
            this.LSMsgElement.current.handleOpenStay('blue', 'Connecting...');
            const resp = await bleDeviceLS.connect();
            if (resp !== STATUS_FAILURE) {
                this.setState({ isConnected: true });
                this.LSMsgElement.current.handleOpenStay('blue', 'Entering Calibration Mode...');
                await this.initCalib();
                setTimeout(() => {
                    this.LSMsgElement.current.handleClose();
                }, CALIB_MSG_DELAY);
            } else {
                this.LSMsgElement.current.handleOpenStay('red', 'Failed to Connect');
                setTimeout(() => {
                  this.LSMsgElement.current.handleClose();
                }, 2000);
            }
          } catch (error) {
            console.error('Failed to connect:', error);
          }
        }
    };
      
      handleDisconnect = async () => {
        try {
          await bleDeviceLS.setData(POSTURE_BUTTONS['REST']);
          await bleDeviceLS.disconnect();
        } catch (error) {
          console.error('Failed to disconnect:', error);
        } finally {
          this.setState({ isConnected: false });
        }
      };


    initCalib = async () => {

        // Get the calibration data from the device
        const data = await bleDeviceLS.initCalibration();
        
        // Check if data is not an array
        if (typeof data !== 'object') {
            
            // Display Communication Lost Error
            this.LSMsgElement.current.handleOpenStay('red', 'Communication Lost');
            this.handleDisconnect();
            // Close drawer after a timeout 
            setTimeout(() => {
                this.LSMsgElement.current.handleClose();
                return;
            }, DISCONNECT_MSG_DELAY);
        }
        
        // Update values in clickers
        LS_SERVO_NAMES_L.forEach(name => {
            this.horzInputClickerRefsL[name].current.updateValue(data[name]);
            //this.horzInputClickerRefsL[name].current.doneUpdate();
        });
        LS_SERVO_NAMES_R.forEach(name => {
            this.horzInputClickerRefsR[name].current.updateValue(data[name]);
            //this.horzInputClickerRefsR[name].current.doneUpdate();
        });
        this.vertInputClickerRefs[LS_SERVO_NAMES[0]].current.updateValue(data[LS_SERVO_NAMES[0]]);
        //this.vertInputClickerRefs[LS_SERVO_NAMES[0]].current.doneUpdate();
        
        this.setState({ calibrationMode: true });
    }

    hPosture = async (posture_type) => {

        // Disable components
        this.setState({ componentsDisabled: true });

        try {
            // Open drawer
            this.LSMsgElement.current.handleOpenStay('blue', 'Setting Posture...');
            await bleDeviceLS.setData(POSTURE_BUTTONS[posture_type]);
            // Close drawer after delay
            setTimeout(() => {
                this.LSMsgElement.current.handleClose();
                if (posture_type === POSTURE_BUTTON_NAMES['WALK']) {
                    bleDeviceLS.setData(POSTURE_BUTTONS['STAND UP']).then(() => {
                        setTimeout(() => {
                            this.LSMsgElement.current.handleClose();
                            this.setState({ componentsDisabled: false, calibrationMode: false });
                        });
                    })
                } else {
                    this.setState({ componentsDisabled: false, calibrationMode: false });
                }
            }, POSTURE_MSG_DELAY);
        } catch (error) {
            // Communication Lost
            this.LSMsgElement.current.handleOpenStay('red', 'Communication Lost');
            this.handleDisconnect();
            // Close drawer after a timeout
            setTimeout(() => {
                this.LSMsgElement.current.handleClose();
                this.setState({ componentsDisabled: false, calibrationMode: false });
            }, DISCONNECT_MSG_DELAY);
            
        }
    }

    hSliderChange = async (newValue, servo_name) => {

        // Disable all components
        this.setState({ componentsDisabled: true });
        
        let data_array = [
            LS_SERVO_INDICES[servo_name],
            newValue,
        ];
        
        // Send the new value to the device
        const res = await bleDeviceLS.setCalibration(data_array);
        if (res === STATUS_FAILURE) {
            // Display Communication Lost Error
            this.LSMsgElement.current.handleOpenStay('red', 'Communication Lost');
            this.handleDisconnect();
            // Close drawer after a timeout
            setTimeout(() => {
                this.LSMsgElement.current.handleClose();

                // Re-enable all components
                this.setState({ componentsDisabled: false });
            }, DISCONNECT_MSG_DELAY);
        } else {
            // Pause
            await new Promise(resolve => setTimeout(resolve, UPDATE_DELAY));

            // Re-enable components
            this.setState({ componentsDisabled: false });
        }
    }

    hCalibrate = async () => {
        // Open drawer
        this.LSMsgElement.current.handleOpenStay('blue', 'Entering Calibration Mode...');
        await this.initCalib();
        // Close drawer after a delay
        setTimeout(() => {
            this.LSMsgElement.current.handleClose();
        }, CALIB_MSG_DELAY);
        this.setState({ calibrationMode: true });
    }

    hAbortCalib = async () => {

        // Open drawer
        this.LSMsgElement.current.handleOpenStay('blue', 'Reverting Calibration Values...');
        await bleDeviceLS.abortCalibration();
        this.setState({ calibrationMode: false });
        // Close drawer after a delay
        setTimeout(() => {
            this.LSMsgElement.current.handleClose();
            this.hCalibrate();

        }, CALIB_MSG_DELAY);
    }

    hSaveCalib = async () => {
        // Open drawer
        this.LSMsgElement.current.handleOpenStay('blue', 'Saving Calibration Values...');
        await bleDeviceLS.saveCalibration();
        this.setState({ calibrationMode: false });
        // Close drawer after a delay
        setTimeout(() => {
            this.LSMsgElement.current.handleClose();
            //this.hCalibrate();
        }, CALIB_MSG_DELAY);
    }


    render() {
        const { classes } = this.props;
        const { isConnected, componentsDisabled, calibrationMode } = this.state;

        // Check if userAccess array includes "LocoScout"
        const hasLocoScoutAccess = this.context.AuthInstance.userAccess.includes("LocoScout");

        // For TopBar Route Highlight
        const currentPath = this.props.location.pathname;
    
        // What to Display - Selectable Views Based on activeStep
        return (
            <React.Fragment>
            <CssBaseline />
            <Topbar currentPath={currentPath} />
                {hasLocoScoutAccess ? (
                    <div className={classes.root}>
                        <div className = {classes.buttonBar}>
                            <div style = {{ marginLeft: '20px' }}>
                                <Button
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleConnect}
                                >
                                    {isConnected ? 'Disconnect' : 'Connect'}
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
                                <div className={classes.btnsTopRowR}>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        color="primary"
                                        onClick={this.hCalibrate}
                                        disabled={!isConnected || componentsDisabled || calibrationMode}
                                    >
                                        {CALIB_BUTTONS['CALIBRATE']}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={this.hSaveCalib}
                                        disabled={!isConnected || componentsDisabled || !calibrationMode}
                                    >
                                        {CALIB_BUTTONS['SAVE']}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={this.hAbortCalib}
                                        disabled={!isConnected || componentsDisabled || !calibrationMode}
                                    >
                                        {CALIB_BUTTONS['ABORT']}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={() => this.hPosture(POSTURE_BUTTON_NAMES['REST'])}
                                        disabled={!isConnected || componentsDisabled || calibrationMode}
                                    >
                                        {POSTURE_BUTTON_NAMES['REST']}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={() => this.hPosture(POSTURE_BUTTON_NAMES['STAND UP'])}
                                        disabled={!isConnected || componentsDisabled || calibrationMode}
                                    >
                                        {POSTURE_BUTTON_NAMES['STAND UP']}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={() => this.hPosture(POSTURE_BUTTON_NAMES['WALK'])}
                                        disabled={!isConnected || componentsDisabled || calibrationMode}
                                    >
                                        {POSTURE_BUTTON_NAMES['WALK']}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {LS_SERVO_NAMES_R.map((name, index) => (
                                    <React.Fragment key={name + String(index)}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <HorizontalInputClicker 
                                                ref={this.horzInputClickerRefsR[name]}
                                                key={index}
                                                defaultValue={0} 
                                                min={CALIB_MIN} 
                                                max={CALIB_MAX}
                                                width={INPUT_CLICK_W}
                                                disabled={!isConnected || componentsDisabled || !calibrationMode}
                                                title={[LS_SERVO_NAMES_R[index], `[${LS_SERVO_INDICES[LS_SERVO_NAMES_R[index]]}]`]}
                                                hSliderChange={this.hSliderChange}
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginRight: INPUT_CLICK_W }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <HorizontalInputClicker
                                    ref = {this.vertInputClickerRefs[LS_SERVO_NAMES[0]]}
                                    defaultValue={0}
                                    min={CALIB_MIN}
                                    max={CALIB_MAX}
                                    width={INPUT_CLICK_W}
                                    disabled={!isConnected || componentsDisabled || !calibrationMode}
                                    title={[LS_SERVO_NAMES[0], `[${LS_SERVO_INDICES[LS_SERVO_NAMES[0]]}]`]}
                                    hSliderChange={this.hSliderChange}
                                />
                            </div>
                            <div>
                                <img 
                                    src={bittleServos} 
                                    alt="servo indices" 
                                    style={{ width: '100%', height: '225px', borderRadius: '10px' }} 
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                            {LS_SERVO_NAMES_L.map((name, index) => (
                                <React.Fragment key={name + String(index)}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <HorizontalInputClicker
                                            ref={this.horzInputClickerRefsL[name]}
                                            key={index}
                                            defaultValue={0} 
                                            min={CALIB_MIN} 
                                            max={CALIB_MAX}
                                            width={INPUT_CLICK_W}
                                            disabled={!isConnected || componentsDisabled || !calibrationMode}
                                            title={[LS_SERVO_NAMES_L[index], `[${LS_SERVO_INDICES[LS_SERVO_NAMES_L[index]]}]`]}
                                            hSliderChange={this.hSliderChange}
                                        />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className={classes.about_root}>
                        <div className={classes.text}>
                            <Typography 
                                className={`${classes.marginRight} ${classes.marginBottom}`} 
                                variant="h4"
                                style = {{ color: 'white' }}
                            >
                                Discover more about<br></br>LocoScout and quadruped robotics
                            </Typography>
                            <Button
                                className={classes.gradientButton}
                                href={LS_SITE_LINK}
                                target="_blank"
                                rel="noopener"
                            >
                                CodeMyRobots
                            </Button>
                        </div>
                        <img 
                            src={bittleDog} 
                            alt="Bittle Dog" 
                            className={classes.image}
                        />
                    </div>
                )}
                <GeneralMessage
                    ref = {this.LSMsgElement}
                    onClose = {() => void 0}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(DogRC));
