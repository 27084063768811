
const SERVICE_UUID = '0000ffe0-0000-1000-8000-00805f9b34fb';
const CHARACTERISTIC_TX = '0000ffe1-0000-1000-8000-00805f9b34fb';
const CHARACTERISTIC_RX = '0000ffe2-0000-1000-8000-00805f9b34fb';

const SERIAL_DELAY = 100; // ms
const BLE_CONNECT_TIMEOUT = 2000; // ms
const MAX_CHUNK_SIZE = 15; // # of bytes

const DATA_CHECK_RATE = 10; // ms

export const BLE_NOTIFICATION = 'characteristicvaluechanged';

export const CALIB_MIN = -20; // deg
export const CALIB_MAX = 20; // deg

export const STATUS_SUCCESS = 1;
export const STATUS_FAILURE = 0;

export const LS_SITE_LINK = "https://codemyrobots.com/product/locoscout/";


class BluetoothDevice {

    constructor() {
        this.isConnected = false;
        this.initialMessagesFlag = true;
        this.resolveInitialMessagesPromise = null;
        this.initialMessagesPromise = null;
        this.timeoutId = null;
        this.intervalId = null;
        this.buffer = '';
        //
        this.data = '';
        this.appendData = false;
        this.disableCharacter = null;
        //
        this.boundHandleNotifications = this.handleNotifications.bind(this);

        
        this.bufferSize = 100;

        this.optionNames = ['Light', 'IR Distance', 'Touch', 'PIR'];

        this.options = {
            'Light': {refer: 'READ_LIGHT', count: 2, range: ['min', 'min'], offset: 1,},
            'IR Distance': {refer: 'READ_IR', count: 2, range: ['min', 'min'], offset: 5,},
            'Touch': {refer: 'READ_TOUCH', count: 2, range: ['fixed'], fixed: [-0.1, 1.1] },
            'PIR': {refer: 'READ_PIR', count: 1, range: ['fixed'], fixed: [-0.1, 1.1]},
        };
        
        this.self = {
            READ_LIGHT: {
                value: ["Ra", "Ra"],
                hex: [0x10, 0x11],
                names: ['Light 1', 'Light 2'],
                ylabels: ["Light (#)", "Light (#)"],
                arrays: ['lightArray1', 'lightArray2'],
            },
            READ_IR: {
                value: ["Ra", "Ra"],
                hex: [0x11, 0x10],
                names: ['IR1', 'IR2'],
                ylabels: ["IR (mm)", "IR (mm)"],
                arrays: ['irArray1', 'irArray2'],
            },
            READ_TOUCH: {
                value: ["Rd", "Rd"],
                hex: [0x06, 0x07],
                names: ['Touch', 'Touch'],
                ylabels: ["Touch (OFF/ON)", "Touch (OFF/ON)"],
                arrays: ['touchArray1', 'touchArray2'],
            },
            READ_PIR: {
                value: ["Rd"],
                hex: [0x08],
                names: ['PIR'],
                ylabels: ["PIR (OFF/ON)"],
                arrays: ['pirArray'],
            },
            lightArray1: [],
            lightArray2: [],
            irArray1: [],
            irArray2: [],
            touchArray1: [],
            touchArray2: [],
            pirArray: [],
        };
    }

    resetArrays = () => {
        this.self.lightArray1 = []
        this.self.lightArray2 = []
        this.self.irArray1 = []
        this.self.irArray2 = []
        this.self.touchArray1 = []
        this.self.touchArray2 = []
        this.self.pirArray = []
    }

    send_command = async (ref_obj) => {

        // make copy of value array
        let data_msg = [...ref_obj.value];
        let hex_msg = [...ref_obj.hex];
        // make copy of array names array
        let names = [...ref_obj.arrays]; 
        // process data as get it, for arrays
        // iterate through data_msg array
        
        for (let i = 0; i < data_msg.length; i++) {

            // enable appending
            this.enableAppending(data_msg[i][0]);
            // send each command
            //await this.setData(data_msg[i]);
            await this.setWithBin(data_msg[i], hex_msg[i]);
            
            // wait for the data to be complete
            await this.waitForDataComplete();
            
            // process data for corresponding array
            let data_value = this.data.split('\n')[0];
            
            // remove "=" and convert to integer
            data_value = parseInt(data_value.slice(1));
            if (isNaN(data_value)) { data_value = 0; }

            /*
            #define MAX_READING 1024
            int rawR = analogRead(SENSOR1) - 24;
            float dR = rawR < 30 ? rawR / 4.0 : 200.0 / sqrt(MAX_READING - rawR);

            //int rawL = analogRead(SENSOR2) - 24;
            //float dL = rawL < 30 ? rawL / 4.0 : 200.0 / sqrt(MAX_READING - rawL);
            */
            /*
            // Convert if certain type
            // if "IR" in ref_obj.names[i]
            if (ref_obj.names[i].includes("IR") && !ref_obj.names[i].includes("PIR")) {
                data_value -= 24;
                if (data_value < 30) {
                    data_value = data_value / 4.0;
                } else {
                    data_value = 200.0 / Math.sqrt(1024 - data_value);
                }
            }*/

            // Special processing for IR/Light
            if (ref_obj.names[i].includes("IR")) {
                const x = data_value;
                const result = -56.5 +
                    5.13 * x +
                    -0.0416 * Math.pow(x, 2) +
                    1.56E-04 * Math.pow(x, 3) +
                    -2.64E-07 * Math.pow(x, 4) +
                    1.64E-10 * Math.pow(x, 5) +
                    7.71E-15 * Math.pow(x, 6);
                data_value = result;
            } else if (ref_obj.names[i].includes("Light")) {
                const x = data_value;
                const result = -2.38 +
                    0.0492 * x +
                    1.71E-03 * Math.pow(x, 2) +
                    -4.47E-06 * Math.pow(x, 3) +
                    5.65E-09 * Math.pow(x, 4);
                data_value = result < 0 ? 0 : result;
            }

            // other extra processing if IR or Light
            if (ref_obj.names[i].includes("IR") || ref_obj.names[i].includes("Light")) {
                // Filtering logic
                const oldData = [...this.self[names[i]]];
                const newData = data_value;

                // Example filtering logic: simple moving average
                const windowSize = 15; // Define the window size for the moving average
                if (oldData.length >= windowSize) {
                    const sum = oldData.slice(-windowSize).reduce((acc, val) => acc + val, 0);
                    const average = sum / windowSize;
                    data_value = (average + newData) / 2; // Update data_value with the filtered value
                } else if (((oldData.length > 1)) && (oldData.length < windowSize)) {
                    // average as many data points as available
                    const sum = oldData.reduce((acc, val) => acc + val, 0);
                    const average = sum / oldData.length;
                    data_value = (average + newData) / 2; // Update data_value with the filtered value
                }
            }

            this.self[names[i]].push(data_value);
            // Handle buffer size
            if (this.self[names[i]].length > this.bufferSize) {
                this.self[names[i]].shift();
            }

        }
    }
    
    get_data_array = (type) => {
        return type.map(name => this.self[name]);
    };

    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }

    get_buffer_size = () => {
        return this.bufferSize
    }

    reset() {
        //window.bleComms.device = null;
        window.bleComms.server = null;
        window.bleComms.rxCharacteristic = null;
        window.bleComms.txCharacteristic = null;
        this.isConnected = false;
        this.initialMessagesFlag = true;
        this.resolveInitialMessagesPromise = null;
        this.initialMessagesPromise = null;
        this.timeoutId = null;
        this.intervalId = null;
        this.buffer = '';
        //
        this.data = '';
        this.appendData = false;
        this.disableCharacter = null;
    }

    async connectDeviceOnly() {
        try {
            this.reset();

            // Request Bluetooth Device (must be called within a user gesture)
            window.bleComms.device = await navigator.bluetooth.requestDevice({ 
                optionalServices: [SERVICE_UUID],
                filters: [{
                    namePrefix: 'Petoi'
                }]
            });
            console.log('Device selected:', window.bleComms.device.name);

            // Return the status to the caller
            return STATUS_SUCCESS;
        } catch (error) {
            console.error('Failed to select device:', error);

            // Return failure status if device selection fails
            return STATUS_FAILURE;
        }
    }

    // BLE Connect Function (must be called from a user gesture)
    async connect() {
        try {
            this.reset();

            // Request Bluetooth Device (must be called within a user gesture)
            window.bleComms.device = await navigator.bluetooth.requestDevice({ 
                optionalServices: [SERVICE_UUID],
                filters: [{
                    namePrefix: 'Petoi'
                }]
            });
            console.log('Device selected:', window.bleComms.device.name);

            // Proceed to attempt connection
            const status = await this.ble_connect_internal();
            if (status === STATUS_SUCCESS) {
                console.log('BLE connection successful.');
            } else {
                console.error('BLE connection failed.');
            }

            // Return the status to the caller
            return status;
        } catch (error) {
            console.error('Failed to select device:', error);

            // Return failure status if device selection fails
            return STATUS_FAILURE;
        }
    }

    async ble_connect_internal() {
        const MAX_CONNECTION_RETRIES = 4;
        let connectionRetryCount = 0;
    
        // Ensure a device has been selected
        if (!window.bleComms.device) {
            console.error('No device selected. Please select a device first.');
            return STATUS_FAILURE;
        }
    
        while (connectionRetryCount < MAX_CONNECTION_RETRIES) {
            
            this.data = '';

            // Create a new promise for the initial messages
            this.initialMessagesPromise = new Promise(resolve => {
                this.resolveInitialMessagesPromise = resolve;
            });
    
            try {
                // Connect to GATT Server
                try {
                    window.bleComms.server = await window.bleComms.device.gatt.connect();
                } catch (gattError) {
                    console.error('Failed to connect to GATT Server:', gattError);
                    throw gattError;
                }
                
                // Wait 0.2 seconds before attempting to get Primary Service
                //await new Promise(resolve => setTimeout(resolve, 200));
    
                // Retry mechanism for getting the primary service
                const MAX_SERVICE_RETRIES = 4;
                let serviceRetryCount = 0;
                let service;
    
                while (serviceRetryCount < MAX_SERVICE_RETRIES) {
                    try {
                        service = await window.bleComms.server.getPrimaryService(SERVICE_UUID);
                        break; // Exit the loop if successful
                    } catch (serviceError) {
                        console.error('Failed to get Primary Service:', serviceError);
    
                        if (serviceError.name === 'NetworkError' && serviceError.message.includes('GATT Server is disconnected')) {
                            console.warn('GATT Server is disconnected. Attempting to reconnect...');
                            serviceRetryCount++;
    
                            // Attempt to reconnect
                            window.bleComms.server = await window.bleComms.device.gatt.connect();
                        } else if (serviceError.name === 'NotSupportedError') {
                            console.warn('NotSupportedError encountered. Retrying...');
                            serviceRetryCount++;
                        } else {
                            // For other errors, rethrow
                            throw serviceError;
                        }
    
                        if (serviceRetryCount >= MAX_SERVICE_RETRIES) {
                            throw new Error('Failed to get Primary Service after maximum retries.');
                        }
                    }
                }
    
                // Get RX Characteristic
                try {
                    window.bleComms.rxCharacteristic = await service.getCharacteristic(CHARACTERISTIC_RX);
                } catch (rxError) {
                    console.error('Failed to get RX Characteristic:', rxError);
                    throw rxError;
                }
    
                // Get TX Characteristic
                try {
                    window.bleComms.txCharacteristic = await service.getCharacteristic(CHARACTERISTIC_TX);
                } catch (txError) {
                    console.error('Failed to get TX Characteristic:', txError);
                    throw txError;
                }
    
                this.isConnected = true;
    
                // Remove previous event listeners
                window.bleComms.txCharacteristic.removeEventListener('characteristicvaluechanged', this.boundHandleNotifications);
    
                // Add the listener
                window.bleComms.txCharacteristic.addEventListener('characteristicvaluechanged', this.boundHandleNotifications);
    
                this.initialMessagesFlag = true;
    
                // Start notifications on TX Characteristic
                try {
                    await window.bleComms.txCharacteristic.startNotifications();
                } catch (notificationError) {
                    console.error('Failed to start notifications:', notificationError);
                    throw notificationError;
                }
    
                // Start the timer before awaiting initialMessagesPromise.
                this.timeoutId = setTimeout(this.resolveInitialMessagesPromise, BLE_CONNECT_TIMEOUT);
    
                // Wait for the initial messages to be received
                await this.initialMessagesPromise;
                this.initialMessagesFlag = false;
    
                // Wait 2 seconds before sending another message
                await new Promise(resolve => setTimeout(resolve, 2000));

                // Connection successful
                return STATUS_SUCCESS;
            } catch (error) {
                console.error(`Connection attempt ${connectionRetryCount + 1} failed:`, error);
    
                connectionRetryCount++;
    
                if (connectionRetryCount >= MAX_CONNECTION_RETRIES) {
                    console.error('Maximum connection retries reached.');
                    return STATUS_FAILURE;
                } else {
                    console.log(`Retrying connection (${connectionRetryCount}/${MAX_CONNECTION_RETRIES})...`);
                    // Wait 0.5 seconds before retrying
                    //await new Promise(resolve => setTimeout(resolve, 100));
                }
            }
        }
    }

/*
    async connect() {
        try {
            this.reset();

            // Create a new promise for the initial messages
            this.initialMessagesPromise = new Promise(resolve => {
                this.resolveInitialMessagesPromise = resolve;
            });

            window.bleComms.device = await navigator.bluetooth.requestDevice({ 
                //acceptAllDevices: true, 
                optionalServices: [SERVICE_UUID], // replace with your service UUID
                filters: [{
                    namePrefix: 'Petoi'
                }]
            });
            window.bleComms.server = await window.bleComms.device.gatt.connect();

            await new Promise(resolve => setTimeout(resolve, 200));

            const MAX_RETRIES = 3;
            let retryCount = 0;
            let service;
    
            while (retryCount < MAX_RETRIES) {
                try {
                    //console.log(`Attempting to get Primary Service (attempt ${retryCount + 1})...`);
                    service = await window.bleComms.server.getPrimaryService(SERVICE_UUID);
                    //console.log('Primary Service obtained:', service);
                    break; // Exit the loop if successful
                } catch (serviceError) {
                    console.error('Failed to get Primary Service:', serviceError);
    
                    if (serviceError.name === 'NetworkError' && serviceError.message.includes('GATT Server is disconnected')) {
                        console.warn('GATT Server is disconnected. Attempting to reconnect...');
                        retryCount++;
    
                        // Attempt to reconnect
                        //console.log('Reconnecting to GATT Server...');
                        window.bleComms.server = await window.bleComms.device.gatt.connect();
                        //console.log('Reconnected to GATT Server.');
                    } else {
                        // For other errors, rethrow
                        throw serviceError;
                    }
    
                    // If maximum retries reached, throw an error
                    if (retryCount >= MAX_RETRIES) {
                        throw new Error('Failed to get Primary Service after maximum retries.');
                    }
                }
            }
            
            window.bleComms.rxCharacteristic = await service.getCharacteristic(CHARACTERISTIC_RX);
            window.bleComms.txCharacteristic = await service.getCharacteristic(CHARACTERISTIC_TX);
            this.isConnected = true;

            window.bleComms.txCharacteristic.removeEventListener('characteristicvaluechanged', this.boundHandleNotifications);
            
            // Add event listener
            window.bleComms.txCharacteristic.addEventListener('characteristicvaluechanged', this.boundHandleNotifications);
            
            //this.initialMessagesFlag = true;
            await window.bleComms.txCharacteristic.startNotifications()
            
            // Start the timer before awaiting initialMessagesPromise.
            this.timeoutId = setTimeout(this.resolveInitialMessagesPromise, BLE_CONNECT_TIMEOUT);

            // Wait for the initial messages to be received
            await this.initialMessagesPromise;
            this.initialMessagesFlag = false;

            // wait 2 seconds before sending another message
            await new Promise(resolve => setTimeout(resolve, 2000));

            return STATUS_SUCCESS;
        } catch (error) {
            //console.log('connect error: ', error)
            return STATUS_FAILURE;
        }
    }
        */

    async disconnect() {
        if (window.bleComms.device) {
            window.bleComms.device.gatt.disconnect();
            this.reset();
            // Wait for 2.5 seconds before resolving
            await new Promise(resolve => setTimeout(resolve, 2500));
        }
    }

    async setWithBin(cmd_data, hex_data) {
        if (window.bleComms.txCharacteristic) {

            let encoder = new TextEncoder('utf-8');
            let data = cmd_data + String.fromCharCode(hex_data); // Append character to data
            let dataArray = encoder.encode(data); // Convert to ArrayBuffer
            await window.bleComms.txCharacteristic.writeValue(dataArray);
            //await new Promise(resolve => setTimeout(resolve, SERIAL_DELAY));
        }
    }

    async setData(data, add_data = "") {
        if (window.bleComms.txCharacteristic) {
            data += add_data;
            const encoder = new TextEncoder();
            const encodedData = encoder.encode(data);
            
            // Split the data into chunks and send each chunk separately.
            for (let i = 0; i < encodedData.length; i += MAX_CHUNK_SIZE) {
                const chunk = encodedData.slice(i, i + MAX_CHUNK_SIZE);
                await window.bleComms.txCharacteristic.writeValue(chunk);
            };
            // wait for SERIAL_DELAY
            await new Promise(resolve => setTimeout(resolve, SERIAL_DELAY));
            return STATUS_SUCCESS;
        } else {
            return STATUS_FAILURE;
        }
    }

    enableAppending(disableCharacter) {
        this.data = '';
        this.appendData = true;
        this.disableCharacter = disableCharacter;
    }

    disableAppending() {
        this.appendData = false;
    }

    waitForDataComplete(timeout = 2000) { // Default timeout is 2000ms
        return Promise.race([
          new Promise((resolve, reject) => {
            this.intervalId = setInterval(() => {
              if (!this.appendData) {
                clearInterval(this.intervalId);
                resolve();
              }
            }, DATA_CHECK_RATE); // Check every x ms
          }),
          this.timeoutId = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout))
        ]);
    }

    clearWaiting = () => {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
    }

    async handleNotifications(event) {

        const value = event.target.value;
        const decoder = new TextDecoder();
        const dataString = decoder.decode(value);
        //console.log('Received data: ' + dataString);

        // If initialMessages
        if (this.initialMessagesFlag) {
            //console.log(dataString);
            // Clear the existing timeout if there is one.
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            if (dataString.includes("Ready!")) {
                
                //console.log('Ready message received');
                this.resolveInitialMessagesPromise();
            } else {
                this.timeoutId = setTimeout(this.resolveInitialMessagesPromise, BLE_CONNECT_TIMEOUT);
            }
        } else {
            if (!this.appendData) {
                return;
            }
            
            const data = dataString;
            this.data += data;
            if (data.includes(this.disableCharacter)) {
                this.appendData = false;
            }

        }

        
        /*// Check if the buffer contains a full message
        const endOfMessageIndex = this.buffer.indexOf('\n');
        if (endOfMessageIndex !== -1) {
            const message = this.buffer.substring(0, endOfMessageIndex);
            //console.log('Received message: ' + message);

            // Remove the processed message from the buffer
            this.buffer = this.buffer.substring(endOfMessageIndex + 1);
        }*/
    }


    async initCalibration() {
        try {
            this.enableAppending('c');
            await this.setData('c');
            await this.waitForDataComplete();

            // process and return the data
            const data = this.data;
            // Split data by new lines
            let dataArr = data.split('\n');
            // Clean data
            let cleanedData = dataArr[1].replace(/[\t\r]/g, '');
            // Split data by commas
            let dataAngles = cleanedData.split(',');
            dataAngles = dataAngles.map(item => parseInt(item));

            // Data structure for output
            let calibAngles = {};
            LS_SERVO_NAMES.forEach((name, index) => {
                calibAngles[name] = dataAngles[LS_SERVO_INDICES[name]];
            });
            return calibAngles;
        } catch (err) {
            console.log(err)
            return STATUS_FAILURE;
        }
    }

    async setCalibration(data_array) {

        try {
            // Create Message String of the form cjointIndex1 offset1 jointIndex2 offset2 
                // where data_array = [jointIndex1, offset1, jointIndex2, offset2, ...]
            let message = 'c';
            for (let i = 0; i < data_array.length; i+=2) {
                message += `${data_array[i]} ${data_array[i+1]} `;
            }
            // Remove the trailing space
            message = message.trim();
            // Send the message over BLE
            await this.setData(message);

            return STATUS_SUCCESS;
        } catch {
            return STATUS_FAILURE;
        }
    }

    async saveCalibration() {
        try {
            await this.setData('s');
            return STATUS_SUCCESS;
        } catch {
            return STATUS_FAILURE;
        }
    }

    async abortCalibration() {
        try {
            await this.setData('a');
            return STATUS_SUCCESS;
        } catch {
            return STATUS_FAILURE;
        }
    }


    
};

export const bleDeviceLS = new BluetoothDevice();

export const LS_CALIB_CMDS = { 
    CALIBRATE: 'c',  // Send robot to calibration posture, attach legs, fine-tune joint offsets
    // Format: 'c jointIndex1 offset1 jointIndex2 offset2 ...'
    SAVE: 's',  // Save the calibration values
    ABORT: 'a',  // Abort the calibration values
}

const LS_BEHAVIORS = {
    // Skills - Behaviors
    COME: 'kcmh',
    BOXING: 'kbx',
    HANDS_UP: 'khu',
    HIGH_FIVE: 'kfiv',
    KICK: 'kkc',
    NOD: 'knd',
    SHAKE: 'khsk',
    WAVE: 'kwh',
    BACKFLIP: 'kbf',
    FRONT_FLIP: 'kff',
    HANDSTAND: 'khds',
    JUMP: 'kjmp',
    MOONWALK: 'kmw',
    PUSH_UPS: 'kpu',
    PUSH_UPS_ONE_HAND: 'kpu1',
    ROLL_OVER: 'krl',
    DIG: 'kdg',
    PLAY_DEAD: 'kpd',
    SCRATCH: 'kscrh',
    SNIFF: 'ksnf',
    ALERT: 'kts',
    TABLE: 'ktbl',
    CHECK: 'kck',
    CHEERS: 'kchr',
    GOOD_DOG: 'kgdb',
    HUG: 'khg',
    HI: 'khi',
    ANGER: 'kang',
    RECOVERY_MODE: 'krc'
}


export const BEHAVIOR_DELAY_MAP = {
    [LS_BEHAVIORS.COME]: "5.6",
    [LS_BEHAVIORS.BOXING]: "7.8",
    [LS_BEHAVIORS.HANDS_UP]: "7.0",
    [LS_BEHAVIORS.HIGH_FIVE]: "5.2",
    [LS_BEHAVIORS.KICK]: "1.1",
    [LS_BEHAVIORS.NOD]: "1.0",
    [LS_BEHAVIORS.SHAKE]: "6.0",
    [LS_BEHAVIORS.WAVE]: "1.7",
    [LS_BEHAVIORS.BACKFLIP]: "2.5",
    [LS_BEHAVIORS.FRONT_FLIP]: "2.5",
    [LS_BEHAVIORS.HANDSTAND]: "4.5",
    [LS_BEHAVIORS.JUMP]: "1.5",
    [LS_BEHAVIORS.MOONWALK]: "5.2",
    [LS_BEHAVIORS.PUSH_UPS]: "5.2",
    [LS_BEHAVIORS.PUSH_UPS_ONE_HAND]: "9.7",
    [LS_BEHAVIORS.ROLL_OVER]: "4.2",
    [LS_BEHAVIORS.DIG]: "3.4",
    [LS_BEHAVIORS.PLAY_DEAD]: "11.1",
    [LS_BEHAVIORS.SCRATCH]: "3.4",
    [LS_BEHAVIORS.SNIFF]: "3.4",
    [LS_BEHAVIORS.ALERT]: "1.6",
    [LS_BEHAVIORS.TABLE]: "2.7",
    [LS_BEHAVIORS.CHECK]: "5.3",
    [LS_BEHAVIORS.CHEERS]: "3.5",
    [LS_BEHAVIORS.GOOD_DOG]: "2.1",
    [LS_BEHAVIORS.HUG]: "8.8",
    [LS_BEHAVIORS.HI]: "5.3",
    [LS_BEHAVIORS.ANGER]: "3.5",
}


export const LS_INFO = {
    'Version':'?',  // Get the model and software version
    'Joint Angles':'j',  // Print joint angles, specific or all. Example: 'j 8' prints the angle of joint 8.
    'DOF':'L',  // List of DOFx joint angles
    'Gryo Data':'v',  // Print the Gyro data once
    'Verobse Gyro Data':'V',  // Toggle verbosely print Gyro data
};

export const LS_CONTROLS = {
    'Rest':'d',  // Disable Servos or Rest command
    'Beep': 'b',  // Beep; format: 'b note1 duration1 note2 duration2 ...', note is in Hz and duration in seconds = 1/value
    'Toggle Gyro': 'G',  // Toggle on/off the gyro 
    'Pause':'p',  // Pause command
    'Servo Incremental': 'm',  // Servo Incremental command
    'Servo Simultaneous': 'i',  // Servo Simultaneous command
};

export const POSTURES_MAP = {
    'Resting':'RESTING',
    'Sitting':'SITTING',
    'Standing':'STANDING',
    'Stretching':'STRETCHING',
    'Drop Front Legs': 'DROP_FRONT_LEGS',
    'Landing': 'LANDING',
    'Calibration':'CALIBRATION',
};
export const POSTURES = Object.keys(POSTURES_MAP);


export const GAITS_MAP = {
    'Walk': {'F': 'kwkF', 'L': 'kwkL', 'R': 'kwkR', 'X': 'kwkX'},
    'Backward': {'B': 'kbk', 'L': 'kbkL', 'R': 'kbkR', 'X': 'kbkX'},
    'Spin': {'L': 'kvtL', 'R': 'kvtR',}, // 'X': 'kvtX'
    'Trot': {'F': 'ktrF', 'L': 'ktrL', 'R': 'ktrR', 'X': 'ktrX'},
    'Crawl': {'F': 'kcrF', 'L': 'kcrL', 'R': 'kcrR', 'X': 'kcrX'},
    'Push': {'F': 'kphF', 'L': 'kphL', 'R': 'kphR', 'X': 'kphX'},
    'Jump': {'F': 'kjpF'},
};
export const GAITS = Object.keys(GAITS_MAP);
//export const GAITS = ['Bound', 'Backward', 'Crawl', 'Jump', 'Push', 'Trot', 'Step', 'Walk'];

export const GAITS_DIRS = ['Forward', 'Backward', 'Left', 'Right', 'Random'];
export const GAITS_DIRS_MAP = {
    'Forward': 'F',
    'Backward': 'B',
    'Left': 'L',
    'Right': 'R',
}

export const GAITS_DIRS_OPTS = {
    'Walk': ['Forward', 'Left', 'Right', 'Random'],
    'Backward': ['Backward', 'Left', 'Right', 'Random'],
    'Spin': ['Left', 'Right'],
    'Trot': ['Forward', 'Left', 'Right', 'Random'],
    'Crawl': ['Forward', 'Left', 'Right', 'Random'],
    'Push': ['Forward', 'Left', 'Right', 'Random'],
    'Jump': ['Forward'],
}

export const BEHAVIORS = ['Come Here', 'Boxing', 'Hands Up', 'High Five', 'Kick', 'Nod', 'Shake', 'Wave', 'Backflip', 'Front Flip', 'Handstand', 'Jump', 'Moonwalk', 'Push Ups', 'Push Ups One Hand', 'Roll Over', 'Dig', 'Play Dead', 'Scratch', 'Sniff', 'Alert', 'Table', 'Check', 'Cheers', 'Good Dog', 'Hug', 'Hi', 'Anger', 'Recovery Mode'];
export const BEHAVIORS_MAP = {
    'Come Here': 'kcmh',  
    'Boxing': 'kbx',
    'Hands Up': 'khu',
    'High Five': 'kfiv',
    'Kick': 'kkc',
    'Nod': 'knd',
    'Shake': 'khsk',
    'Wave': 'kwh',
    'Backflip': 'kbf',
    'Front Flip': 'kff',
    'Handstand': 'khds',
    'Jump': 'kjmp',
    'Moonwalk': 'kmw',
    'Push Ups': 'kpu',
    'Push Ups One Hand': 'kpu1',
    'Roll Over': 'krl',
    'Dig': 'kdg',
    'Play Dead': 'kpd',
    'Scratch': 'kscrh',
    'Sniff': 'ksnf',
    'Alert': 'kts',
    'Table': 'ktbl',
    'Check': 'kck',
    'Cheers': 'kchr',
    'Good Dog': 'kgdb',
    'Hug': 'khg',
    'Hi': 'khi',
    'Anger': 'kang',
    'Recovery Mode': 'krc',
};
export const BEHAVIORS_NAMES_MAP = {
    'Come Here': "COME_HERE",
    'Boxing': "BOXING",
    'Hands Up': "HANDS_UP",
    'High Five': "HIGH_FIVE",
    'Kick': "KICK",
    'Nod': "NOD",
    'Shake': "SHAKE",
    'Wave': "WAVE",
    'Backflip': "BACKFLIP",
    'Front Flip': "FRONT_FLIP",
    'Handstand': "HANDSTAND",
    'Jump': "JUMP",
    'Moonwalk': "MOONWALK",
    'Push Ups': "PUSH_UPS",
    'Push Ups One Hand': "PUSH_UPS_ONE_HAND",
    'Roll Over': "ROLL_OVER",
    'Dig': "DIG",
    'Play Dead': "PLAY_DEAD",
    'Scratch': "SCRATCH",
    'Sniff': "SNIFF",
    'Alert': "ALERT",
    'Table': "TABLE",
    'Check': "CHECK",
    'Cheers': "CHEERS",
    'Good Dog': "GOOD_DOG",
    'Hug': "HUG",
    'Hi': "HI",
    'Anger': "ANGER",
    'Recovery Mode': "RECOVERY_MODE",
}



const BEHAVIORS_BASIC = [
    'Come Here', 'Boxing',
    'Hands Up',  'High Five',
    'Kick',      'Nod',
    'Shake',     'Wave'
]
const BEHAVIOR_PERFORMANCE = [
    'Backflip',         'Front Flip',
    'Handstand',        'Jump',
    'Moonwalk',         'Push Ups',
    'Push Ups One Hand', 'Roll Over'
]
const BEHAVIOR_PLAYFUL = [
    'Dig',      'Play Dead',
    'Scratch',  'Sniff',
    'Alert',    'Table',
    'Check',    'Cheers',
    'Good Dog', 'Hug',
    'Hi',       'Anger'
]

export const BEHAVIOR_CATEGORIES = ['Basic Tricks', 'Performance Tricks', 'Playful Gestures'];
export const BEHAVIOR_CATEGORIES_MAP = {
    'Basic Tricks': BEHAVIORS_BASIC,
    'Performance Tricks': BEHAVIOR_PERFORMANCE,
    'Playful Gestures': BEHAVIOR_PLAYFUL,
};


export const LS_SKILLS = {
    'posture': {
        'Resting':'krest',
        'Sitting':'ksit',
        'Standing':'kup',
        'Stretching':'kstr',
        'Drop Front Legs': 'kdropped',
        'Landing': 'klnd',
        'Calibration':'kzero',
    },
    'gait': {
        'Walk': {'F': 'kwkF', 'L': 'kwkL', 'R': 'kwkR', 'X': 'kwkX'},
        'Trot': {'F': 'ktrF', 'L': 'ktrL', 'R': 'ktrR', 'X': 'ktrX'},
        'Crawl': {'F': 'kcrF', 'L': 'kcrL', 'R': 'kcrR', 'X': 'kcrX'},
        'Push': {'F': 'kphF', 'L': 'kphL', 'R': 'kphR', 'X': 'kphX'},
        'Backward': {'B': 'kbk', 'L': 'kbkL', 'R': 'kbkR', 'X': 'kbkX'},
        'Spin': {'L': 'kvtL', 'R': 'kvtR'},
        'Jump': {'F': 'kjpF'},
    },
    'behavior': {
        // Basic Tricks
        'Come Here': 'kcmh',  
        'Boxing': 'kbx',
        'Hands Up': 'khu',
        'High Five': 'kfiv',
        'Kick': 'kkc',
        'Nod': 'knd',
        'Shake': 'khsk',
        'Wave': 'kwh',
        // Performance Tricks
        'Backflip': 'kbf',
        'Front Flip': 'kff',
        'Handstand': 'khds',
        'Jump': 'kjmp',
        'Moonwalk': 'kmw',
        'Push Ups': 'kpu',
        'Push Ups One Hand': 'kpu1',
        'Roll Over': 'krl',
        // Playful Gestures
        'Dig': 'kdg',
        'Play Dead': 'kpd',
        'Scratch': 'kscrh',
        'Sniff': 'ksnf',
        'Alert': 'kts',
        'Table': 'ktbl',
        // Interactive Responses
        'Check': 'kck',
        'Cheers': 'kchr',
        'Good Dog': 'kgdb',
        'Hug': 'khg',
        'Hi': 'khi',
        'Anger': 'kang',
        // Special Functions
        'Recovery Mode': 'krc',
    }
};

export const LS_SKILLS_DIRS = {
    'Forward': 'F',
    'Backward': 'B',
    'Left': 'L',
    'Right': 'R',
    'Random': 'X'
};


export const SERVO_RANGES = {
    'Neck [0]': [-70, 70],
    'Front Left Shoulder [8]': [-135, 70],
    'Front Left Elbow [12]': [-70, 135],
    'Back Left Hip [11]': [-70, 135],
    'Back Left Knee [15]': [-70, 135],
    'Front Right Shoulder [9]': [-135, 70],
    'Front Right Elbow [13]': [-70, 135],
    'Back Right Hip [10]': [-70, 135],
    'Back Right Knee [14]': [-70, 135],
}

export const LS_SERVO_INDICES = {
    'Neck': 0,
    'Front Left Elbow': 12,
    'Front Left Shoulder': 8,
    'Back Left Hip': 11,
    'Back Left Knee': 15,
    'Front Right Elbow': 13,
    'Front Right Shoulder': 9,
    'Back Right Hip': 10,
    'Back Right Knee': 14,
};

const LS_SERVO_INDICES_BLOCK_ORDER = {
    'Neck': 0,
    'Front Left Shoulder': 8,
    'Front Left Elbow': 12,
    'Front Right Shoulder': 9,
    'Front Right Elbow': 13,
    'Back Left Hip': 11,
    'Back Left Knee': 15,
    'Back Right Hip': 10,
    'Back Right Knee': 14,
}
export const LS_SERVO_NAMES = Object.keys(LS_SERVO_INDICES);
export const LS_SERVO_NAMES_FULL = Object.keys(LS_SERVO_INDICES).map(key => `${key} (${LS_SERVO_INDICES[key]})`);
export const LS_SERVO_NAMES_L = Object.keys(LS_SERVO_INDICES).filter(name => name.includes('Left'));
export const LS_SERVO_NAMES_R = Object.keys(LS_SERVO_INDICES).filter(name => name.includes('Right'));

export const LS_SERVO_NAMES_W_IND = Object.keys(LS_SERVO_INDICES_BLOCK_ORDER).map(key => `${key} [${LS_SERVO_INDICES[key]}]`);