// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import logoBuzz from '../../../images/blocks/LocoScout/bbuzz.svg'
import logoLoop from '../../../images/blocks/LocoScout/bloop.svg'
import logoPosture from '../../../images/blocks/LocoScout/bposture.svg'
import logoBehavior from '../../../images/blocks/LocoScout/bbehavior.svg'
import logoGait from '../../../images/blocks/LocoScout/bgait.svg'
import logoServo from '../../../images/blocks/LocoScout/bservo.svg'
import logoPause from '../../../images/blocks/LocoScout/bpause.svg'

import {POSTURES, POSTURES_MAP, 
        GAITS, GAITS_DIRS_OPTS, 
        BEHAVIOR_CATEGORIES_MAP, BEHAVIOR_CATEGORIES, 
        BEHAVIOR_DELAY_MAP, BEHAVIORS_MAP, BEHAVIORS_NAMES_MAP,
        LS_SERVO_NAMES,
        LS_SERVO_INDICES,
        SERVO_RANGES,
        LS_SERVO_NAMES_W_IND
    } from '../../../libraries/LocoScoutStore';
import { Checkbox } from '@material-ui/core';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        //justify: 'flex-start',
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    pretitleText: {
        color: "white",
        fontSize: theme.typography.h4.fontSize,
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    regularText: {
        color: "white",
        //margin: theme.spacing(1),
        marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    servoText: {
        color: "white",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    servoInputField: {
        width: "100%",
        color: "white",
        //marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.4),
        textAlign: "center",
    },
    textSelect: {
        color: "white",
        marginTop: theme.spacing(0.6),   
        marginLeft: theme.spacing(1),     
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        border: '1px solid #000',
    },
});

const useStyles = makeStyles((theme) => ({
    input: {
      fontSize: theme.typography.h4.fontSize,
      color: "white",
      marginTop: theme.spacing(0.3),
    },
}));

function EditableLabel({ title, onTitleChange }) {
    
    const classes = useStyles();

    return (
      <TextField
        value={title}
        onChange={onTitleChange}
        InputProps={{
            classes: {
              input: classes.input,
            },
            inputProps: {
              maxLength: 3, // Limit the number of characters
              style: { textAlign: "right", }
            },
            disableUnderline: true,
          }}
        style={{
            minWidth: '13px', // Limit the width of the input field
            maxWidth: '69px', // Limit the width of the input field
            //background: "blue",
        }}
      />
    );
  }

// Component Class - ProgramDisplay
class LocoScoutBlocks extends React.Component {

    state = {
        LSBlocksOptions: {
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 135, 
                color: '#8057c1',
                control: {
                    in1: 1, 
                    //pretitle: 'A',
                },
                codeRef(ctrl) {
                    //return [`# ${ctrl.pretitle}: Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                text: ['Repeat ', 'times'],
                code: [],
                in1Width: '70px',
                in1Type: 'int',
                in1Limits: [0, 250], // Count
                text1: 'Repeat',
                text2: 'times',
                blockImg: logoLoop,
            },
            iloopend:
            {        
                id: 0,
                title: 'LoopEnd',
                height: 135, 
                color: '#8057c1',
                control: {},
                code: [],
            },
            ibuzz: 
            {
                id: 0,
                title: 'Buzzer',
                height: 135, 
                color: '#ec4078',
                in1Width: '40px',
                in1Type: 'float',
                in1Limits: [0, 60], // seconds
                control: {
                    sel1: 'loco_scout.NOTE["C"]', sel1Txt: 'C', 
                    sel2: 'loco_scout.OCTAVE["4"]', sel2Txt: '4', 
                    sel3: 'loco_scout.DURATION["whole"]', sel3Txt: 'Whole Note',
                    in1: 0,
                },
                codeRef(ctrl) {
                    let codeArray = [
                        `# Play a ${ctrl.sel1Txt} ${ctrl.sel3Txt} at Octave ${ctrl.sel2Txt}`, 
                        `note = ${ctrl.sel1}`,
                        `octave = ${ctrl.sel2}`,
                        `duration = ${ctrl.sel3}`,
                        `loco_scout.play_buzzer([note, octave, duration])`
                    ];
                    
                    return codeArray;
                },
                text: ['Play a ', 'then pause for ', 'seconds'],
                sel1Options: ['C', 'C Sharp', 'D', 'E Flat', 'E', 'F', 'F Sharp', 'G', 'G Sharp', 'A', 'B Flat', 'B'],
                sel2Options: ['4', '5', '6', '7'],
                sel3Options: ['Whole Note', 'Half Note', 'Quarter Note', 'Eighth Note'],
                sel1map: {
                    'C': 'loco_scout.NOTE["C"]', 
                    'C Sharp': 'loco_scout.NOTE["C_SHARP"]', 
                    'D': 'loco_scout.NOTE["D"]',
                    'E Flat': 'loco_scout.NOTE["E_FLAT"]',  
                    'E': 'loco_scout.NOTE["E"]', 
                    'F': 'loco_scout.NOTE["F"]', 
                    'F Sharp': 'loco_scout.NOTE["F_SHARP"]', 
                    'G': 'loco_scout.NOTE["G"]', 
                    'G Sharp': 'loco_scout.NOTE["G_SHARP"]', 
                    'A': 'loco_scout.NOTE["A"]', 
                    'B Flat': 'loco_scout.NOTE["B_FLAT"]', 
                    'B': 'loco_scout.NOTE["B"]'
                },
                sel2map: {
                    '4': 'loco_scout.OCTAVE["4"]', 
                    '5': 'loco_scout.OCTAVE["5"]', 
                    '6': 'loco_scout.OCTAVE["6"]', 
                    '7': 'loco_scout.OCTAVE["7"]', 
                },
                sel3map: {
                    'Whole Note': 'loco_scout.DURATION["whole"]', 
                    'Half Note': 'loco_scout.DURATION["half"]', 
                    'Quarter Note': 'loco_scout.DURATION["quarter"]', 
                    'Eighth Note': 'loco_scout.DURATION["eighth"]', 
                },
                blockImg: logoBuzz,
            },
            iposture: 
            {
                id: 0,
                title: 'Posture',
                height: 135, 
                color: '#009788',
                control: {sel1: POSTURES_MAP[POSTURES[0]], sel1Txt: POSTURES[0], },
                codeRef(ctrl) {
                    let codeArray = [`# Posture ${ctrl.sel1Txt}`,
                                     `loco_scout.run_posture(loco_scout.${ctrl.sel1})`
                                    ];
                    return codeArray;
                },
                text: ['', ' then continue program after ', 'seconds'],
                sel1Options: [...POSTURES],
                sel1map: {...POSTURES_MAP},
                blockImg: logoPosture,
            },
            ibehavior: 
            {
                id: 0,
                title: 'Behavior',
                height: 135, 
                color: '#FF2424',
                control: {
                    in1: BEHAVIOR_DELAY_MAP[BEHAVIORS_MAP[BEHAVIOR_CATEGORIES_MAP[BEHAVIOR_CATEGORIES[0]][0]]], 
                    selSrc1: BEHAVIOR_CATEGORIES[0], selSrc1Txt: BEHAVIOR_CATEGORIES[0], 
                    selSrc1Dep: 'sel1',
                    sel1: BEHAVIOR_CATEGORIES_MAP[BEHAVIOR_CATEGORIES[0]][0], 
                    sel1Txt: BEHAVIOR_CATEGORIES_MAP[BEHAVIOR_CATEGORIES[0]][0], 
                    mode: 'Basic', modeIndex: 0,
                },
                codeRef(ctrl) {
                    let codeArray = []
                    let nameStr = BEHAVIORS_NAMES_MAP[ctrl.sel1]
                    if (ctrl.mode === 'Basic') {
                        codeArray.push(`# Run ${ctrl.sel1Txt} behavior`);
                        codeArray.push(`loco_scout.run_behavior(loco_scout.${nameStr})`)
                    } else {
                        codeArray.push(`# Run ${ctrl.sel1Txt} behavior and continue after ${Number(ctrl.in1) * 1000} milliseconds`);
                        codeArray.push(`loco_scout.run_behavior(loco_scout.${nameStr}, ${Number(ctrl.in1) * 1000})`)
                    }
                    //codeArray.push('loco_scout.run_posture(loco_scout.REST)')
                    return codeArray;
                },
                text: ['', 'then continue program after ', 'seconds'],
                sel1Options: [...BEHAVIOR_CATEGORIES_MAP[BEHAVIOR_CATEGORIES[0]]],
                selSrc1Options: [...BEHAVIOR_CATEGORIES],
                selSrc1DepMap: {...BEHAVIOR_CATEGORIES_MAP},
                sel1Map: {...BEHAVIORS_NAMES_MAP},
                inWidth: '40px',
                in1Type: 'float',
                in1Limits: [0, 60], // Seconds
                modeOptions: ['Basic', 'Advanced'],
                modeIndices: 2,
                heightMode: {'Basic': 135, 'Advanced': 215},
                blockImg: logoBehavior,
            },
            igait: 
            {
                id: 0,
                title: 'Gait',
                height: 135, 
                color: '#4DAF4E',
                control: {
                    in1: 0, 
                    selSrc1: GAITS[0], selSrc1Txt: GAITS[0],
                    selSrc1Dep: 'sel1',
                    sel1: GAITS_DIRS_OPTS[GAITS[0]][0], 
                    sel1Txt: GAITS_DIRS_OPTS[GAITS[0]][0],
                },
                codeRef(ctrl) {
                    // combine selSrc1 and sel1 with a _ and make each all caps
                    let nameStr = ctrl.selSrc1.toUpperCase() + '_' + ctrl.sel1.toUpperCase()
                    let codeArray = [`# ${ctrl.sel1Txt} for ${Number(ctrl.in1) * 1000} milliseconds, then stop`,
                                     `loco_scout.run_gait(loco_scout.${nameStr}, ${Number(ctrl.in1) * 1000})`,
                                    ];
                    return codeArray;
                },
                text: ['', 'for ', 'seconds'],
                sel1Options: [...GAITS_DIRS_OPTS[GAITS[0]]],
                selSrc1Options: [...GAITS],
                selSrc1DepMap: {...GAITS_DIRS_OPTS},
                inWidth: '40px',
                in1Type: 'float',
                in1Limits: [0, 60], // Seconds
                blockImg: logoGait,
            },
            iservo:
            {
                id: 0,
                title: 'Servo',
                height: 135, 
                color: '#F16749',
                control: {
                    in1: 0, in2: 0, in3: 0, in4: 0,
                    in5: 0, in6: 0, in7: 0, in8: 0,
                    in9: 0, in10: 0,
                    sel1: LS_SERVO_NAMES_W_IND[0], sel1Txt: LS_SERVO_NAMES_W_IND[0],
                    mode: 'Basic', modeIndex: 0,
                    checkedState: new Array(9).fill(true),
                },
                codeRef(ctrl) {
                    let codeArray = []
                    if (ctrl.mode === 'Basic') {
                        // Assemble list of all Servo angles for Servos listed in LS_SERVO_NAMES, where each is 0 except for that matching sel1Txt
                        //let servoData = []
                        //let servoIndex = LS_SERVO_NAMES.indexOf(ctrl.sel1Txt)
                        //if (servoIndex >= 0 && servoIndex < LS_SERVO_NAMES.length) {
                        //    servoData.push(LS_SERVO_INDICES[LS_SERVO_NAMES[servoIndex]]);
                        //    servoData.push(ctrl.in1);
                        //}
                        let servoIndexMatch = ctrl.sel1Txt.match(/\[(\d+)\]/);
                        let servoData = [parseInt(servoIndexMatch[1], 10), ctrl.in1];
                        let servoDataString = JSON.stringify(servoData);
                        // Assemble the servo_simulanteous() command
                        codeArray.push(`servo_data = ${servoDataString} # Set ${ctrl.sel1Txt} Servo to ${ctrl.in1} degrees`)
                        codeArray.push(`# Send servo_data list`)
                        codeArray.push('loco_scout.servo_simulanteous(servo_data)')
                    } else {
                        // Function to extract numbers between []
                        const extractNumbers = (array) => {
                            const regex = /\[(\d+)\]/;
                            return array.map(item => {
                                const match = item.match(regex);
                                return match ? parseInt(match[1], 10) : null;
                            }).filter(num => num !== null); // Filter out any null values
                        };

                        const extractedNumbers = extractNumbers(LS_SERVO_NAMES_W_IND);
                        codeArray.push()
                        let checkedCount = ctrl.checkedState.reduce((count, isChecked) => count + (isChecked ? 1 : 0), 0);
                        if (checkedCount === 0) {
                            codeArray.push('servo_data = []')
                        } else {
                            codeArray.push('servo_data = [')
                        }
                        
                        for (let i = 0; i < extractedNumbers.length; i++) {
                            let hName = 'in' + (i + 1);
                            // only add to servo_data if the checkbox is checked
                            if (ctrl.checkedState[i]) { 
                                codeArray.push(`    ${extractedNumbers[i]}, ${ctrl[hName]}, # Set ${LS_SERVO_NAMES_W_IND[i]} Servo to ${ctrl[hName]} degrees`);
                            }
                        }
                        // 
                        codeArray[codeArray.length - 1] = codeArray[codeArray.length - 1].replace(/,([^,]*)$/, '$1');
                        if (checkedCount !== 0) {
                            codeArray.push(']')
                        }
                        codeArray.push(`loco_scout.servo_simulanteous(servo_data)`)
                    }
                    return codeArray;
                },
                text: ['then continue program after ', 'seconds'],
                text_adv: [...LS_SERVO_NAMES_W_IND],
                text_degrees: '\u00B0',
                sel1Options: [...LS_SERVO_NAMES_W_IND],
                inWidth: '40px',
                in1Type: 'int', in2Type: 'int', in3Type: 'int', in4Type: 'int',
                in5Type: 'int', in6Type: 'int', in7Type: 'int', in8Type: 'int',
                in9Type: 'int', in10Type: 'int',
                modeOptions: ['Basic', 'Advanced'],
                modeIndices: 2,
                heightMode: {'Basic': 135, 'Advanced': 450},
                in1Cust: '',in2Cust: '',in3Cust: '',
                in4Cust: '',in5Cust: '',in6Cust: '',
                in7Cust: '',in8Cust: '',in9Cust: '',
                in10Cust: '',
                boundRef(x, name) {
                    let m = x
                    if (name !== 'in10') {
                        const range = SERVO_RANGES[name];    
                        m = Math.max(m, range[0]);
                        m = Math.min(m, range[1]);
                    } else {    
                        m = Math.max(m, 0);
                        m = Math.min(m, 60);
                    }
                    return m;
                },
                blockImg: logoServo,
            },
            ipause:
            {
                id: 0,
                title: 'Pause',
                height: 135, 
                color: '#fbc22d',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Pause for ${ctrl.in1} seconds`, `time.sleep(${ctrl.in1})`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'float',
                text1: 'Pause for',
                text2: 'seconds',
                in1Limits: [0, 60],
                
                blockImg: logoPause,
            },
            //

        },
        renderType: this.props.blockType,
        btn1: 0, btn2: 0, btn3: 0, btn4: 0,
        btn1Txt: '',
        btn2Txt: '',
        btn3Txt: '',
        btn4Txt: '',
        btn9Txt: '',
        btn10Txt: '',
        btn11Txt: '',
        sel1Txt: '',
        sel2Txt: '',
        sel3Txt: '',
        selSrc1Txt: '',
        sel1: 0,
        sel2: 0,
        sel3: 0,
        selSrc1: 0,
        in1: 0, in2: 0, in3: 0, in4: 0,
        in5: 0, in6: 0, in7: 0, in8: 0,
        in9: 0, in10: 0,
        mode: '',
        //pretitle: 'A',
        checkedState: new Array(10).fill(true),
        selectAllState: true,
    }


    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        //console.log(updates)
        
        if ('name' in updates) {
            this.setState( {renderType: updates.name} );
        }
        let tempLSBlocks = this.state.LSBlocksOptions;
        
        tempLSBlocks[this.state.renderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            
            keyNames.forEach((val, i) => {
                tempLSBlocks[this.state.renderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                // Check if val starts with 'selSrc'
                if (val.startsWith('selSrc') &&  val.includes('Dep')) {
                    // Use the value of selSrc[x] to update the Options sub property
                    let optionKey = updates.control[val] + 'Options';
                    // val without the "Dep"
                    let srcVal = val.replace("Dep", "");
                    let hDepMap = val + 'Map';
                    // Update the Dependent Options sub property
                    tempLSBlocks[this.state.renderType][optionKey] = 
                        tempLSBlocks[this.state.renderType][hDepMap][tempLSBlocks[this.state.renderType].control[srcVal]];
                }
                // Check if val is checkedState
                if (val === 'checkedState') {
                    // Check if all are true
                    let allTrue = updates.control[val].every((val) => val === true);
                    // set selectAllState to allTrue
                    this.setState( {selectAllState: allTrue} );
                }

                if (tempLSBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
                    tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
                }
            });
   
            if (tempLSBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
                tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
            }
            
        } else {
            if ('sel1' in tempLSBlocks[this.state.renderType].control) {
                this.setState( {sel1Txt: tempLSBlocks[this.state.renderType].control.sel1Txt} );
            }
            if ('sel2' in tempLSBlocks[this.state.renderType].control) {
                this.setState( {sel2Txt: tempLSBlocks[this.state.renderType].control.sel2Txt} );
            }
            if ('sel3' in tempLSBlocks[this.state.renderType].control) {
                this.setState( {sel3Txt: tempLSBlocks[this.state.renderType].control.sel3Txt} );
            }

            // loop for each selSrc[x] in control, update the corresponding sel[x] and sel[x]Txt
            let keyNames = Object.keys(tempLSBlocks[this.state.renderType].control);
            keyNames.forEach((val, i) => {
                if (val.includes('selSrc') && val.includes('Txt')) {
                    // Update selSrc[x]Txt in state from control's sel[x]Txt
                    this.setState( {[val]: tempLSBlocks[this.state.renderType].control[val]} );
                }
                if (val.includes('in1')) {
                    this.setState( {[val]: tempLSBlocks[this.state.renderType].control[val]} );
                }
            });
            
            if ('mode' in tempLSBlocks[this.state.renderType].control) {
                this.setState( {mode: tempLSBlocks[this.state.renderType].control.mode} );
            }

            if ('checkedState' in tempLSBlocks[this.state.renderType].control) {
                this.setState( {checkedState: tempLSBlocks[this.state.renderType].control.checkedState} );
            }

            if (tempLSBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
                tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
            }
        }

        if (tempLSBlocks[this.state.renderType].hasOwnProperty('heightMode')) {
            tempLSBlocks[this.state.renderType].height = 
                tempLSBlocks[this.state.renderType].heightMode[tempLSBlocks[this.state.renderType].control.mode]
        }

        this.setState( {LSBlocksOptions: tempLSBlocks} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    handleIn = (event) => {
        
        let m = event.target.value;
        this.setState( {[event.target.name]: m} );
    }

    handleInOnBlur = (event, name, extra_name = "") => {
        
        if (!this.state.hasOwnProperty(name)) {
            return
        }
        let m = this.state[name]
        let inName = name

        let inLimits = inName + 'Limits'
        let inCust = inName + 'Cust'
        let inType = inName + "Type"

        let tempLSBlocks = this.state.LSBlocksOptions;

        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if (inLimits in tempLSBlocks[this.state.renderType]) {
                if (n < tempLSBlocks[this.state.renderType][inLimits][0]) {
                    if (tempLSBlocks[this.state.renderType][inLimits][0] < 0) {
                        n = 0
                        m = "0"
                    } else {
                        n = tempLSBlocks[this.state.renderType][inLimits][0]
                        m = String(tempLSBlocks[this.state.renderType][inLimits][0])
                    }
                } else if (n > tempLSBlocks[this.state.renderType][inLimits][1]) {
                    n = tempLSBlocks[this.state.renderType][inLimits][1]
                    m = String(tempLSBlocks[this.state.renderType][inLimits][1])
                }
            } else if (inCust in tempLSBlocks[this.state.renderType]) {
                n = tempLSBlocks[this.state.renderType].boundRef(n, extra_name)
                m = String(n)
            }
            if (tempLSBlocks[this.state.renderType][inType] === 'int') {
                tempLSBlocks[this.state.renderType].control[inName] = Math.floor(n);
                m = String(Math.floor(n))
                tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
            } else {
                tempLSBlocks[this.state.renderType].control[inName] = n;
                tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
            }
        } else {
            if (inLimits in tempLSBlocks[this.state.renderType]) {
                if (tempLSBlocks[this.state.renderType][inLimits][0] < 0) {
                    m = "0"
                } else {
                    m = String(tempLSBlocks[this.state.renderType][inLimits][0])
                }
            } else {
                m = "0"
            }
        }

        this.setState( {LSBlocksOptions: tempLSBlocks, [name]: m} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }


    handleSelect = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';
        
        let tempLSBlocks = this.state.LSBlocksOptions;

        if (hMap in tempLSBlocks[this.state.renderType]) {
            tempLSBlocks[this.state.renderType].control[hName] = tempLSBlocks[this.state.renderType][hMap][event.target.value];                
            tempLSBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLSBlocks[this.state.renderType].control[hText]} );
        } else {
            tempLSBlocks[this.state.renderType].control[hName] = event.target.value;                
            tempLSBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLSBlocks[this.state.renderType].control[hText]} );
        }
        
        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);

        this.setState( {LSBlocksOptions: tempLSBlocks} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    handleSelectBehavior = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';
        
        let tempLSBlocks = this.state.LSBlocksOptions;

        if (hMap in tempLSBlocks[this.state.renderType]) {
            tempLSBlocks[this.state.renderType].control[hName] = tempLSBlocks[this.state.renderType][hMap][event.target.value];                
            tempLSBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLSBlocks[this.state.renderType].control[hText]} );
        } else {
            tempLSBlocks[this.state.renderType].control[hName] = event.target.value;                
            tempLSBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLSBlocks[this.state.renderType].control[hText]} );
        }

        // Update special default values for Advanced mode
        //if (tempLSBlocks[this.state.renderType].control.mode === 'Advanced') {
        if (hName === 'sel1') {
            tempLSBlocks[this.state.renderType].control.in1 = BEHAVIOR_DELAY_MAP[BEHAVIORS_MAP[this.state.LSBlocksOptions[this.state.renderType].control[hName]]];
            this.setState( {in1: tempLSBlocks[this.state.renderType].control.in1} );
        }
        //}
        
        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);

        this.setState( {LSBlocksOptions: tempLSBlocks} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);


    }

    handleSelectSrc = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hDep = hName + 'Dep';
        
        let tempLSBlocks = this.state.LSBlocksOptions;

        let depName = tempLSBlocks[this.state.renderType].control[hDep];
        let hDepMap = hDep + 'Map';

        // Exit if event.target.name === undefined
        if (typeof event.target.name === 'undefined') {
            return;
        }

        // Update core values
        tempLSBlocks[this.state.renderType].control[hName] = event.target.value;
        tempLSBlocks[this.state.renderType].control[hText] = event.target.value;
        this.setState({[hText]: tempLSBlocks[this.state.renderType].control[hText]});

        // Update depName's options
        tempLSBlocks[this.state.renderType][depName + 'Options'] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value];

        // Update depName's sel1 and sel1Txt values
        tempLSBlocks[this.state.renderType].control[depName] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value][0];
        tempLSBlocks[this.state.renderType].control[depName + 'Txt'] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value][0];
        this.setState({[depName + 'Txt']: tempLSBlocks[this.state.renderType].control[depName + 'Txt']});

        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
        
        this.setState( {LSBlocksOptions: tempLSBlocks} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    
    handleSelectSrcBehavior = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hDep = hName + 'Dep';
        
        let tempLSBlocks = this.state.LSBlocksOptions;

        let depName = tempLSBlocks[this.state.renderType].control[hDep];
        let hDepMap = hDep + 'Map';

        // Exit if event.target.name === undefined
        if (typeof event.target.name === 'undefined') {
            return;
        }

        // Update core values
        tempLSBlocks[this.state.renderType].control[hName] = event.target.value;
        tempLSBlocks[this.state.renderType].control[hText] = event.target.value;
        this.setState({[hText]: tempLSBlocks[this.state.renderType].control[hText]});

        // Update depName's options
        tempLSBlocks[this.state.renderType][depName + 'Options'] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value];

        // Update depName's sel1 and sel1Txt values
        tempLSBlocks[this.state.renderType].control[depName] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value][0];
        tempLSBlocks[this.state.renderType].control[depName + 'Txt'] = tempLSBlocks[this.state.renderType][hDepMap][event.target.value][0];
        this.setState({[depName + 'Txt']: tempLSBlocks[this.state.renderType].control[depName + 'Txt']});


        // harcoded reference, not ideal
        tempLSBlocks[this.state.renderType].control.in1 = BEHAVIOR_DELAY_MAP[BEHAVIORS_MAP[tempLSBlocks[this.state.renderType].control[depName + 'Txt']]];
        this.setState( {in1: tempLSBlocks[this.state.renderType].control.in1} );


        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
        
        this.setState( {LSBlocksOptions: tempLSBlocks} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }
    
    handleMode = (event, name) => {

        let tempLSBlocks = this.state.LSBlocksOptions;

        // Check if in1 exists and then check if .control.in1 === this.state.in1
        if (tempLSBlocks[this.state.renderType].control.hasOwnProperty('in1')) {
            if (tempLSBlocks[this.state.renderType].control.in1 !== this.state.in1) {
                // Update state with control.in1
                this.setState( {in1: tempLSBlocks[this.state.renderType].control.in1} );
            }
        }
        // if ibehavior block, reset in1 to default value
        if (this.state.renderType === 'ibehavior') {
            tempLSBlocks[this.state.renderType].control.in1 = BEHAVIOR_DELAY_MAP[BEHAVIORS_MAP[tempLSBlocks[this.state.renderType].control.sel1]];
            this.setState( {in1: tempLSBlocks[this.state.renderType].control.in1} );
        } 


        tempLSBlocks[this.state.renderType].control.modeIndex++;
        if (tempLSBlocks[this.state.renderType].control.modeIndex >= tempLSBlocks[this.state.renderType].modeIndices) {
            tempLSBlocks[this.state.renderType].control.modeIndex = 0;
        }

        tempLSBlocks[this.state.renderType].control.mode = tempLSBlocks[this.state.renderType].modeOptions[tempLSBlocks[this.state.renderType].control.modeIndex];


        // For custom selection options changes 
            // - not very dynamic as is, only works in sel3 used
        if (tempLSBlocks[this.state.renderType].hasOwnProperty('sel3Options')) {
            // Make sure is an object
            if (typeof tempLSBlocks[this.state.renderType].sel3Options === 'object') {
                tempLSBlocks[this.state.renderType].control.sel3Txt = this.state.LSBlocksOptions[this.state.renderType].sel3Options[tempLSBlocks[this.state.renderType].control.mode][0]
                tempLSBlocks[this.state.renderType].control.sel3 = tempLSBlocks[this.state.renderType].sel3map[tempLSBlocks[this.state.renderType].control.sel3Txt]
                // update state itself
                this.setState({sel3Txt: tempLSBlocks[this.state.renderType].control.sel3Txt})
            }
        }


        // Update Mode units if they exist
        if (tempLSBlocks[this.state.renderType].hasOwnProperty('modeUnits')) {
            tempLSBlocks[this.state.renderType].control.modeUnits = tempLSBlocks[this.state.renderType].modeUnits[tempLSBlocks[this.state.renderType].control.mode];
        }

        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);

        if (tempLSBlocks[this.state.renderType].hasOwnProperty('heightMode')) {
            tempLSBlocks[this.state.renderType].height = 
            tempLSBlocks[this.state.renderType].heightMode[tempLSBlocks[this.state.renderType].control.mode]
        }

        this.setState( {LSBlocksOptions: tempLSBlocks, mode: tempLSBlocks[this.state.renderType].control.mode} );
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    handlePreTitle = (event) => {
        
        let tempLSBlocks = this.state.LSBlocksOptions;
        tempLSBlocks[this.state.renderType].control.pretitle = event.target.value;
        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
        this.setState({ 
            LSBlocksOptions: tempLSBlocks,
            pretitle: event.target.value
        })
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    // Handle checkbox change
    handleCheckboxChange = (index) => {
        // Update the control checkState array
        let tempLSBlocks = this.state.LSBlocksOptions;
        let updatedCheckedState = [...this.state.checkedState];
        
        // Update the control checkedState array
        tempLSBlocks[this.state.renderType].control.checkedState[index] = !updatedCheckedState[index];
        updatedCheckedState[index] = !updatedCheckedState[index];

        // if any checkboxes are unchecked, uncheck the select all checkbox
        if (updatedCheckedState.includes(false)) {
            this.setState({selectAllState: false})
        }

        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);
        // Update the state
        this.setState({LSBlockOptions: tempLSBlocks, checkedState: updatedCheckedState})
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    };

    handleSelectAll = () => {

        let tempLSBlocks = this.state.LSBlocksOptions;
        let updatedCheckedState = [];
        if (this.state.selectAllState === true) {
            tempLSBlocks[this.state.renderType].control.checkedState = new Array(9).fill(false);
            updatedCheckedState = new Array(9).fill(false);
        } else {
            tempLSBlocks[this.state.renderType].control.checkedState = new Array(9).fill(true);
            updatedCheckedState = new Array(9).fill(true);
        }

        tempLSBlocks[this.state.renderType].code = tempLSBlocks[this.state.renderType].codeRef(tempLSBlocks[this.state.renderType].control);

        // Update the state
        this.setState({
            LSBlockOptions: tempLSBlocks, 
            checkedState: updatedCheckedState,
            selectAllState: !this.state.selectAllState
        })
        this.props.handleSubState(tempLSBlocks[this.state.renderType]);
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    style={{
                        backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color,
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        {this.state.LSBlocksOptions[this.state.renderType].control?.pretitle !== undefined 
                            ? <EditableLabel
                                title={this.state.LSBlocksOptions[this.state.renderType].control.pretitle}
                                onTitleChange={this.handlePreTitle}
                            />
                            : null
                        }
                        <Typography 
                            className={classes.titleText}
                            variant='h5'
                            style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                        >
                            {this.state.LSBlocksOptions[this.state.renderType].control?.pretitle 
                            ? `: ${this.state.LSBlocksOptions[this.state.renderType].title}` 
                            : `[${this.props.isDisabled ? this.props.i : this.props.i + 1}]: 
                                ${this.state.LSBlocksOptions[this.state.renderType].title}`
                            }
                        </Typography>
                    </div>
                    {this.state.renderType === 'iloop' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                            <Grid item xs={3}>
                                <img 
                                    src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                    style={{
                                        transform: `translate(-30px, -12px)`, 
                                        width:'65px', height: '65px', 
                                        position: 'relative',
                                    }} 
                                    alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{width: this.state.LSBlocksOptions[this.state.renderType].in1Width}}
                                        onChange = {this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                        onClick={(event) => event.target.select()}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text[1]}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'ibuzz' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-30px, -12px)`, 
                                    width:'75px', height: '75px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel2'
                                        value={this.state.sel2Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel2Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel2' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel3'
                                        value={this.state.sel3Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel3Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel3' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'iposture') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-30px, -12px)`, 
                                    width:'75px', height: '75px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'ibehavior') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-30px, -12px)`, 
                                    width:'75px', height: '75px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12} style={{position:'relative', top:'-45px',}}>
                                <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleMode(e, 'mode')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.mode}
                                </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{position:'relative', top:'-35px', transform: `translate(-10px)`}}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, marginRight: '10px'}}
                                        onClick={this.handleSelectSrcBehavior}
                                        disabled={this.props.isDisabled}
                                        name='selSrc1'
                                        value={this.state.selSrc1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].selSrc1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'selSrc1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelectBehavior}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                {this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Advanced' && (
                                    <React.Fragment>
                                        <Grid item xs="auto">
                                            <Typography 
                                                className={classes.regularText}
                                                style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                            >
                                                {this.state.LSBlocksOptions[this.state.renderType].text[1]}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Input 
                                                className={classes.inputField}
                                                name='in1'
                                                style={{width: this.state.LSBlocksOptions[this.state.renderType].inWidth}}
                                                onChange = {this.handleIn}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                                onClick={(event) => event.target.select()}
                                                value={this.state.in1}
                                                disabled={this.props.isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography 
                                                className={classes.regularText}
                                                style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                            >
                                                {this.state.LSBlocksOptions[this.state.renderType].text[2]}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'igait') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-30px, -12px)`, 
                                    width:'75px', height: '75px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, marginRight: '10px'}}
                                        onClick={this.handleSelectSrc}
                                        disabled={this.props.isDisabled}
                                        name='selSrc1'
                                        value={this.state.selSrc1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].selSrc1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'selSrc1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LSBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text[1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{width: this.state.LSBlocksOptions[this.state.renderType].inWidth}}
                                        onChange = {this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                        onClick={(event) => event.target.select()}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text[2]}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'ipause') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-30px, -12px)`, 
                                    //clipPath: 'inset(10px 10px 10px 10px)',
                                    width:'75px', height: '75px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text1}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{
                                            width: this.state.LSBlocksOptions[this.state.renderType].inWidth,
                                        }}
                                        onChange = {this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                        onClick={(event) => event.target.select()}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LSBlocksOptions[this.state.renderType].text2}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'iservo') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        {this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Basic' && (
                            <Grid item xs={2}>
                                <img 
                                    src={this.state.LSBlocksOptions[this.state.renderType].blockImg} 
                                    style={{
                                        transform: `translate(-30px, -12px)`, 
                                        width:'75px', height: '75px', 
                                        position: 'relative',
                                    }} 
                                    alt={this.state.LSBlocksOptions[this.state.renderType].title} 
                                />
                            </Grid>
                        )}
                        <Grid item xs={this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Basic' ? 10 : 12}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12} style={{position:'relative', top:'-35px',}}>
                                <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleMode(e, 'mode')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.mode}
                                </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{position:'relative', top:'-25px', transform: `translate(-10px)`}}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    direction="row"
                                >   
                                {this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Basic' && (
                                    <>
                                        <Grid item xs="auto">
                                            <Select
                                                className={classes.textSelect}
                                                style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color}}
                                                onClick={this.handleSelect}
                                                disabled={this.props.isDisabled}
                                                name='sel1'
                                                value={this.state.sel1Txt}
                                            >
                                            {this.state.LSBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                                return (
                                                    <MenuItem 
                                                        key = {'sel1' + val} 
                                                        value = {val}
                                                        style={{backgroundColor:this.state.LSBlocksOptions[this.state.renderType].color, color: "white"}}
                                                    >{val}</MenuItem>
                                                );
                                            })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Input 
                                                className={classes.inputField}
                                                name='in1'
                                                style={{width: this.state.LSBlocksOptions[this.state.renderType].inWidth}}
                                                onChange = {this.handleIn}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'in1', this.state.sel1Txt)}
                                                onClick={(event) => event.target.select()}
                                                value={this.state.in1}
                                                disabled={this.props.isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography 
                                                className={classes.regularText}
                                                style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                            >
                                                {this.state.LSBlocksOptions[this.state.renderType].text_degrees}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Advanced' && (
                                    <Grid item xs={12}>
                                        <Grid container direction="row" alignItems="center" spacing={1}>
                                            <Checkbox 
                                                checked={this.state.selectAllState}
                                                onChange={() => this.handleSelectAll()} 
                                            />
                                            <Typography 
                                                className={classes.servoText}
                                                style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                            >
                                                Select All
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {this.state.LSBlocksOptions[this.state.renderType].control.mode === 'Advanced' && (
                                    this.state.LSBlocksOptions[this.state.renderType].text_adv.map((text, i) => (
                                        <React.Fragment key = {i}>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" alignItems="center" spacing={1}>
                                                    <Checkbox 
                                                       checked={this.state.checkedState[i]}
                                                       onChange={() => this.handleCheckboxChange(i)} 
                                                    />
                                                    <Typography 
                                                        className={classes.servoText}
                                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                                    >
                                                        {text}
                                                    </Typography>
                                                    <Input 
                                                        className={classes.servoInputField}
                                                        name={`in${i + 1}`}
                                                        style={{width: this.state.LSBlocksOptions[this.state.renderType].inWidth}}
                                                        onChange = {this.handleIn}
                                                        onBlur = {(e) => this.handleInOnBlur(e, `in${i + 1}`, text)}
                                                        onClick={(event) => event.target.select()}
                                                        value={this.state[`in${i + 1}`]}
                                                        disabled={this.props.isDisabled || !this.state.checkedState[i]}
                                                    />
                                                    <Typography 
                                                        className={classes.servoText}
                                                        style={{backgroundColor: this.state.LSBlocksOptions[this.state.renderType].color}}
                                                    >
                                                        {this.state.LSBlocksOptions[this.state.renderType].text_degrees}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                )}
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}

                </Box>
            </React.Fragment>
        );
    }




}


export default withStyles(styles)(LocoScoutBlocks);